import { api } from '@/store/helpers'
import actions from '@/store/helpers/actions'
import {
	ADD_EVENT_EXCEPTION,
	REMOVE_EVENT_EXCEPTION,
} from '@/store/mutation-types'

/**
 * The module's dispatchable actions.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	...actions,

	/**
	 * Create a new event.
	 *
	 * @param {Function} options.dispatch
	 * @param {Number} payload.calendarId
	 * @param {Object} payload.event
	 * @return {Response}
	 */
	async create({ dispatch }, { calendarId, event }) {
		const response = await api()
			.calendars()
			.events()
			.create(calendarId, event)

		const data = response.get('data', {})

		await dispatch('add', data)

		await dispatch(
			'calendars/addEvents',
			{ calendarId: event.calendar_id, events: [data.id] },
			{ root: true }
		)

		return response
	},

	/**
	 * Create a new event exception for the given event id.

	 * @param {Function} options.commit
	 * @param {Number} payload.event
	 * @param {String} payload.starts_at
	 * @return {Promise}
	 */
	async createEventException({ commit }, { event, starts_at }) {
		event = parseInt(event)

		const response = await api()
			.eventExceptions()
			.create(event, starts_at)

		const exception = response.get('data', {})

		if (exception?.event_id && exception?.starts_at) {
			commit(ADD_EVENT_EXCEPTION, exception)
		}

		return response
	},

	/**
	 * Delete the given event.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.state
	 * @param {Function} options.getters
	 * @param {Number} payload.calendarId
	 * @param {Number} payload.eventId
	 * @return {Response}
	 */
	async delete({ dispatch, state, getters }, { calendarId, eventId }) {
		const response = await api()
			.calendars()
			.events()
			.delete(calendarId, eventId)

		await dispatch(
			'calendars/removeEvent',
			{ calendarId, event: eventId },
			{ root: true }
		)

		if (!getters.isPrimaryCalendarEvent(eventId)) {
			return response
		}

		const event = state.items[eventId]

		event.calendars.forEach(async id => {
			await dispatch(
				'calendars/removeEvent',
				{ calendarId: id, event: eventId },
				{ root: true }
			)
		})

		await dispatch('remove', eventId)

		return response
	},

	/**
	 * Find an event by calendar and event id.
	 *
	 * @param {Function} options.dispatch
	 * @param {Number} payload.calendar
	 * @return {Response}
	 */
	async find({ dispatch }, { calendar, id }) {
		const response = await api()
			.calendars()
			.events()
			.find(calendar, id)

		const event = response.get('data', [])

		dispatch('add', event)

		return response
	},

	/**
	 * Fetch all calendar's events.
	 *
	 * @param {Function} options.dispatch
	 * @param {Number} payload.calendar
	 * @param {String} payload.start
	 * @param {String} payload.end
	 * @return {Response}
	 */
	async get({ dispatch }, { calendar, start, end, expanded = false }) {
		const request = api()
			.calendars()
			.events()
			.start(start)
			.end(end)

		if (expanded) {
			request.expanded()
		}

		const response = await request.get(calendar)

		const events = response.get('data', []).map(item => {
			return {
				...item,
				id: expanded ? `${item.id}-${item.starts_at}` : item.id,
			}
		})

		const ids = events.map(event => event.id)

		dispatch('add', events)

		dispatch(
			'calendars/addEvents',
			{ calendarId: calendar, events: ids },
			{ root: true }
		)

		return response
	},

	/**
	 * Remove the given event from shared calendars.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.rootState
	 * @param {Number} payload.calendar
	 * @param {Number} payload.eventId
	 * @param {Object} payload.event
	 * @return {void}
	 */
	async removeEventFromCalendars(
		{ dispatch, rootState },
		{ calendar, eventId, event }
	) {
		const calendarIds = rootState.calendars.ids.filter(id => {
			return !event.calendars.includes(id) && id !== calendar
		})

		calendarIds.forEach(async calendarId => {
			await dispatch(
				'calendars/removeEvent',
				{ calendarId, event: eventId },
				{ root: true }
			)
		})
	},

	/**
	 * Remove event exception.
	 *
	 * @param {Function} options.commit
	 * @param {Object} state
	 * @param {Number} payload.event
	 * @param {Number} payload.exception
	 * @return {void}
	 */
	async removeEventException({ commit, state }, { event, exception }) {
		event = parseInt(event)
		const response = await api()
			.eventExceptions()
			.delete(event, exception)

		if (state.ids.indexOf(event) !== -1) {
			commit(REMOVE_EVENT_EXCEPTION, { event, exception })
		}

		return response
	},

	/**
	 * Update the given event.
	 *
	 * @param {Function} options.dispatch
	 * @param {Number} payload.calendar
	 * @param {Number} payload.eventId
	 * @param {Object} payload.event
	 * @return {Response}
	 */
	async update({ dispatch }, { calendar, eventId, event }) {
		const response = await api()
			.calendars()
			.events()
			.update(calendar, eventId, event)

		if (event.calendars) {
			await dispatch('removeEventFromCalendars', {
				calendar,
				eventId,
				event,
			})
		}

		dispatch('add', response.get('data', {}))

		return response
	},
}
