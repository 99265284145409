import Task from './Task'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

/**
 * Install and start reporting errors to Bugsnag.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class StartBugsnagErrorReporting extends Task {
	/**
	 * Run the asynchronous task and resolve when complete.
	 *
	 * @param {Function} next
	 * @return {void}
	 */
	async run(next) {
		if (this.shouldReportErrors()) {
			this.startBugsnagErrorReporting()
		}

		next()
	}

	/**
	 * Get the Bugsnag API key from the environment.
	 *
	 * @return {String}
	 */
	getBugsnagApiKey() {
		return process.env.VUE_APP_BUGSNAG_API_KEY
	}

	/**
	 * Determine if the application is in a production environment.
	 *
	 * @return {Boolean}
	 */
	isProduction() {
		return process.env.NODE_ENV === 'production'
	}

	/**
	 * Determine if the application should report any errors thrown.
	 *
	 * @return {Boolean}
	 */
	shouldReportErrors() {
		if (!this.isProduction()) {
			return false
		}

		return !!this.getBugsnagApiKey()
	}

	/**
	 * Start reporting any errors to Bugsnag.
	 *
	 * @return {void}
	 */
	startBugsnagErrorReporting() {
		Bugsnag.start({
			apiKey: this.getBugsnagApiKey(),
			appVersion: window.clarus.version,
			plugins: [new BugsnagPluginVue()],
			onError: event => {
				const app = this.container.use('app')

				const user = app.store.state.auth.user

				if (user && user.id) {
					event.setUser(
						user.id,
						user.email,
						`${user.first_name} ${user.last_name}`
					)
				}

				const partner = app.store.getters.partner

				event.addMetadata('partner', partner)
			},
		})
	}
}
