import Task from './Task'
import interceptors from '@/config/interceptors'

/**
 * Configure the axios instance & add the request/response interceptors.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class ConfigureAxios extends Task {
	/**
	 * Run the asynchronous task and resolve when complete.
	 *
	 * @param {Function} next
	 * @return {void}
	 */
	async run(next) {
		const http = (window.axios = this.container.use('axios'))

		this._addRequestInterceptors(http)
		this._addResponseInterceptors(http)

		next()
	}

	/**
	 * Add a request interceptor to axios.
	 *
	 * @param {Axios} axios
	 * @param {Function} interceptor
	 * @return {void}
	 */
	_addRequestInterceptor(axios, interceptor) {
		axios.interceptors.request.use(interceptor, error =>
			Promise.reject(error)
		)
	}

	/**
	 * Add each of the request interceptors.
	 *
	 * @param {Axios} axios
	 * @return {void}
	 */
	_addRequestInterceptors(axios) {
		interceptors.request.forEach(interceptor => {
			this._addRequestInterceptor(axios, interceptor)
		})
	}

	/**
	 * Add a response interceptor to axios.
	 *
	 * @param {Axios} axios
	 * @param {Object} interceptor
	 * @return {void}
	 */
	_addResponseInterceptor(axios, { success, error }) {
		if (!success) {
			success = response => response
		}

		if (!error) {
			error = error => Promise.reject(error)
		}

		axios.interceptors.response.use(success, error)
	}

	/**
	 * Add each of the response interceptors.
	 *
	 * @param {Axios} axios
	 * @return {void}
	 */
	_addResponseInterceptors(axios) {
		interceptors.response.forEach(interceptor => {
			this._addResponseInterceptor(axios, interceptor)
		})
	}
}
