import moment from 'moment'
import filters from '@/config/filters'

/**
 * Call PDF builder.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class CallPDF {
	/**
	 * Create a new CallPDF instance.
	 *
	 * @param {Builder} builder
	 */
	constructor(builder) {
		this.call = {}
		this.pdf = builder
	}

	/**
	 * Export the given call to a PDF.
	 *
	 * @param {Object} call
	 * @return {void}
	 */
	make(call) {
		this.call = call
		this._setBaseStyles()

		this._addHeaderColumns()
			._addEmptyLine()
			._addTimeOfCall()
			._addPatientInformation()
			._addEmptyLine(5)
			._addDismissalReason()
			._addEmptyLine()
			._addNotesTable()
			._addEmptyLine()
			._addHistoryTable()
			._download()
	}

	/**
	 * Add the dismissal reason to the pdf file.
	 *
	 * @return {CallPDF}
	 */
	_addDismissalReason() {
		const reason = this.pdf.textWrapper('body')

		reason.text('Dismissal reason: ', 'label')
		reason.text(this.call.completion_message || 'Unavailable')

		return this
	}

	/**
	 * Add the PDF's header columns.
	 *
	 * @return {CallPDF}
	 */
	_addHeaderColumns() {
		const columns = this.pdf.addColumns()

		columns
			.image()
			.setWidth(100)
			.setHeight(50)

		const wrapper = columns.textWrapper()

		wrapper.text('Call Summary\n', 'header')

		wrapper.text(moment().format('MMMM Do YYYY h:mm a'), 'label')

		wrapper.addStyles().right()

		return this
	}

	/**
	 * Add empty line to the pdf file.
	 *
	 * @param {Number} spacing
	 * @return {CallPDF}
	 */
	_addEmptyLine(spacing = 15) {
		this.pdf
			.text()
			.addStyles()
			.margin([0, spacing])

		return this
	}

	/**
	 * Add history table to the pdf file.
	 *
	 * @return {CallPDF}
	 */
	_addHistoryTable() {
		this.pdf
			.text('History')
			.addStyles()
			.italics()

		const table = this.pdf.table([120, '*'])

		const headers = table.addRow()

		headers.text('Date', 'label')
		headers.text('Summary', 'label')

		if (!this.call?.events) {
			return this
		}

		const events = [...this.call.events]

		events
			.sort((a, b) => a.id - b.id)
			.forEach(events => {
				let row = table.addRow()

				const timestamp = moment
					.utc(events.created_at)
					.local()
					.format('M/D/YYYY, h:mm a')

				row.text(timestamp, 'tableText')
				row.text(events.summary, 'tableText')
			})

		return this
	}

	/**
	 * Add notes table to the pdf file.
	 *
	 * @return {CallPDF}
	 */
	_addNotesTable() {
		this.pdf
			.text('Notes')
			.addStyles()
			.italics()

		const table = this.pdf.table([120, 120, '*'])

		const header = table.addRow()

		header.text('Date', 'label')
		header.text('From', 'label')
		header.text('Note', 'label')

		const notes = [...(this.call.notes || [])]

		notes
			.sort((a, b) => a.id - b.id)
			.forEach(note => {
				const row = table.addRow()

				const timestamp = moment
					.utc(note.created_at)
					.local()
					.format('M/D/YYYY, h:mm a')

				row.text(timestamp, 'tableText')

				row.text(note.user.full_name, 'tableText')

				row.text(note.text, 'tableText')
			})

		return this
	}

	/**
	 * Add the patient's DOB to the PDF.
	 *
	 * @return {CallPDF}
	 */
	addPatientDob() {
		const dob = this.pdf.textWrapper('body')
		dob.text('Patient DOB: ', 'label')
		dob.text(this.call.patient_dob || 'Unavailable')

		return this
	}

	/**
	 * Add patient's information to the pdf file.
	 *
	 * @return {CallPDF}
	 */
	_addPatientInformation() {
		return this._addEmptyLine(5)
			.addPatientName()
			._addEmptyLine(5)
			.addPatientProvider()
			._addEmptyLine(5)
			.addPatientPhone()
			._addEmptyLine(5)
			.addPatientDob()
			._addEmptyLine(5)
			.addPatientMessage()
	}

	/**
	 * Add the patient's transcribed message to the PDF.
	 *
	 * @return {CallPDF}
	 */
	addPatientMessage() {
		const message = this.pdf.textWrapper('body')

		message.text('Patient Message: ', 'label')
		message.text(this.call.patient_message || 'Unavailable')

		return this
	}

	/**
	 * Add the patient's name to the PDF.
	 *
	 * @return {CallPDF}
	 */
	addPatientName() {
		const patient = this.pdf.textWrapper('body')

		patient.text('Patient Name: ', 'label')
		patient.text(this.call.patient_name || 'Unavailable')

		return this
	}

	/**
	 * Add the patient phone number to the PDF.
	 *
	 * @return {CallPDF}
	 */
	addPatientPhone() {
		const { callback_number } = this.call

		const phone = this.pdf.textWrapper('body')

		phone.text('Phone Number: ', 'label')

		const number = callback_number
			? filters.formatPhoneNumber(callback_number)
			: 'Unavailable'

		phone.text(number)

		return this
	}

	/**
	 * Add the patient provider's to the PDF.
	 *
	 * @return {CallPDF}
	 */
	addPatientProvider() {
		const { patient_provider } = this.call

		const name = patient_provider
			? patient_provider.full_name
			: 'Unavailable'

		const provider = this.pdf.textWrapper('body')

		provider.text('Provider: ', 'label')

		provider.text(name)

		return this
	}

	/**
	 * Add call's creation time to the pdf file.
	 *
	 * @return {CallPDF}
	 */
	_addTimeOfCall() {
		const wrapper = this.pdf.textWrapper('body')

		wrapper.text('Date/Time of Call: ').useStyle('label')

		const timestamp = moment
			.utc(this.call.created_at)
			.local()
			.format('M/D/YYYY, h:mm a')

		wrapper.text(timestamp)

		return this
	}

	/**
	 * Download pdf file.
	 *
	 * @return {void}
	 */
	_download() {
		this.pdf.download(this._getFileName())
	}

	/**
	 * Get the file name of the PDF.
	 *
	 * @return {String}
	 */
	_getFileName() {
		const timestamp = moment().format('YYYYMMDDHHmmss')

		return `ClarusCallSummary-${timestamp}.pdf`
	}

	/**
	 * Set the pdf's base styles.
	 *
	 * @return {void}
	 */
	_setBaseStyles() {
		this.pdf
			.defineStyle('header')
			.fontSize(16)
			.bold()
			.italics()

		this.pdf
			.defineStyle('body')
			.fontSize(12)
			.margin([0, 5])

		this.pdf
			.defineStyle('label')
			.fontSize(10)
			.bold()
			.italics()

		this.pdf.defineStyle('tableText').fontSize(10)

		this.pdf.defineStyle('updateText').fontSize(8)
	}
}
