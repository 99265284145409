<template>
	<transition name="fade" mode="out-in">
		<div v-if="show" class="loader">
			<div class="loader-bg bg-gray-400"></div>
			<div class="loader-bar" :style="{ width: `${progress}%` }"></div>
		</div>
	</transition>
</template>

<script>
export default {
	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Hide loader bar.
		 */
		hideLoader() {
			setTimeout(() => (this.show = false), 500)
		},

		/**
		 * Finish the loading process.
		 *
		 * @return {void}
		 */
		onComplete() {
			this.resetInterval()

			this.interval = setInterval(() => {
				if (this.progress >= 100) {
					this.hideLoader()

					return clearInterval(this.interval)
				}

				this.progress += 1
			}, 15)
		},

		/**
		 * Start the loading process.
		 *
		 * @param {Object} payload.timeout
		 * @return {void}
		 */
		onStart({ timeout } = { timeout: 3000 }) {
			this.show = true

			this.resetInterval()

			this.progress = 0

			this.timeout = timeout

			this.interval = setInterval(() => {
				if (this.progress >= 98) {
					return clearInterval(this.interval)
				}

				this.progress += 1
			}, this.timeout * 0.01)
		},

		/**
		 * Clear any currently running interval timer.
		 *
		 * @return {void}
		 */
		resetInterval() {
			this.interval = clearInterval(this.interval)
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'LoaderBar',

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.$app
			.off('loading:complete', this.onComplete)
			.off('loading:start', this.onStart)
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	created() {
		this.$app
			.on('loading:complete', this.onComplete)
			.on('loading:start', this.onStart)
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			interval: null,
			progress: 0,
			show: false,
			timeout: 0,
		}
	},
}
</script>
