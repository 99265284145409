import { api } from '@/store/helpers'
import { version } from '../../../../package.json'
import Application from 'App/Foundation/Application'
import {
	LOGOUT,
	SET_SESSION,
	SET_USER,
	SET_VERSION,
} from '@/store/mutation-types'

/**
 * The module's dispatchable actions
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	/**
	 * Check & get the current user's authentication status and data.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Object} options.state
	 * @return {Promise|Boolean}
	 */
	async check({ commit, dispatch, state }) {
		if (!state.session.access_token || !state.user.id) {
			return false
		}

		try {
			const response = await api()
				.users()
				.find(state.user.id)

			await dispatch('setUser', response.get('data', {}))

			return true
		} catch (e) {
			commit(LOGOUT)

			return Promise.reject(false)
		}
	},

	/**
	 * Attempt to authenticate a user with the given credentials.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Object} payload.username
	 * @param {Object} payload.password
	 * @return {Promise}
	 */
	async login({ commit, dispatch }, { username, password }) {
		const response = await api()
			.auth()
			.login(username, password)

		const {
			access_token,
			expires_in,
			refresh_token,
			token_type,
			...user
		} = response.get('data', {})

		const session = {
			access_token,
			expires_in,
			refresh_token,
			token_type,
		}

		commit(SET_SESSION, session)

		await dispatch('setUser', user)

		const app = Application.getInstance()

		app.emit('auth:success', app)

		return response
	},

	/**
	 * Logout the current user.
	 *
	 * @param {Function} options.commit
	 * @return {void}
	 */
	logout({ commit }) {
		commit(LOGOUT)
	},

	/**
	 * Set the authenticated user.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Object} model
	 * @return {void}
	 */
	async setUser({ commit, dispatch }, model) {
		const { ...user } = model

		const partners = await dispatch('partners/get', null, { root: true })

		user.partner_ids = partners.get('data', []).map(partner => partner.id)
		user.messaging_profile_ids = []
		commit(SET_USER, user)
	},

	/**
	 * Set the current code base version.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @return {void}
	 */
	async setVersion({ commit }) {
		commit(SET_VERSION, version)
	},
}
