import Middleware from './Middleware'

/**
 * Authentication booted middleware.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class AuthBooted extends Middleware {
	/**
	 * Handle the incoming route change.
	 *
	 * @param {Object} to
	 * @param {Object} from
	 * @param {Function} next
	 * @return {void}
	 */
	handle(to, from, next) {
		this.isBooted() ? next() : this.waitForNextAuthBootedEvent(next)
	}

	/**
	 * Determine if the Auth instance is booted.
	 *
	 * @return {Boolean}
	 */
	isBooted() {
		return this.app.auth().booted()
	}

	/**
	 * Add the auth booted event listener to the event bus.
	 *
	 * @param {Function} next
	 * @return {void}
	 */
	waitForNextAuthBootedEvent(next) {
		this.app.once('auth:booted', () => next())
	}
}
