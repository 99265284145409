import stateFactory from './state'
import * as helpers from '@/store/helpers'
import {
	ADD_EVENTS,
	ADD_ITEMS,
	REMOVE_EVENT,
	REMOVE_EVENTS,
	RESET,
} from '@/store/mutation-types'

/**
 * The module's state mutators.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	/**
	 * Add the given model(s) to the store.
	 *
	 * @param {Object} state
	 * @param {Array} models
	 */
	[ADD_ITEMS](state, models) {
		helpers.add(state, models)
	},

	/**
	 * Add the event ids to the specified calendar.
	 *
	 * @param {Object} state
	 * @param {Number} payload.id
	 * @param {Array} payload.events
	 * @return {void}
	 */
	[ADD_EVENTS](state, { id, events }) {
		if (!state.items[id].events && events.length > 0) {
			state.items[id].events = []
		}

		events.forEach(event => {
			if (state.items[id].events.indexOf(event) === -1) {
				state.items[id].events.push(event)
			}
		})
	},

	/**
	 * Remove the given event to the specified calendar.
	 *
	 * @param {Object} state
	 * @param {Number} payload.id
	 * @param {Number} payload.event
	 * @return {void}
	 */
	[REMOVE_EVENT](state, { id, event }) {
		const index = state.items[id].events.indexOf(event)

		if (index !== -1) {
			state.items[id].events.splice(index, 1)
		}
	},

	/**
	 * Reset the given state back to its original value.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[RESET](state) {
		helpers.reset(state, stateFactory())
	},

	/**
	 * Clear the calendar events array for all calendars.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[REMOVE_EVENTS](state) {
		state.ids.forEach(calendar_id => {
			state.items[calendar_id].events = []
		})
	},
}
