import store from '@/store'
import router from '@/router'
import Application from './Application'
import ServiceProvider from 'App/Support/ServiceProvider'

/**
 * Application service provider.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class AppServiceProvider extends ServiceProvider {
	/**
	 * Register bindings in the container.
	 *
	 * @return {void}
	 */
	register() {
		this.app.bind('App/Foundation/Container', ioc => ioc)

		this.app.singleton('App/Foundation/Application', ioc => {
			return new Application(ioc, store, router)
		})
	}
}
