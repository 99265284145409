import Vue from 'vue'
import Vuex from 'vuex'
import config from './config'

Vue.use(Vuex)

/**
 * Create a new Vuex state store instance.
 *
 * @type {Vuex}
 */
export default new Vuex.Store(config)
