import Vue from 'vue'
import Task from './Task'
import app from '@/config/app'

/**
 * Set the global window bindings.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class AddGlobalBindings extends Task {
	/**
	 * Run the asynchronous task and resolve when complete.
	 *
	 * @param {Function} next
	 * @return {void}
	 */
	async run(next) {
		window.Vue = Vue

		this._addGlobalBindingsToVue(app.vue)

		next()
	}

	/**
	 * Make the global Vue prototype bindings available in all component instances.
	 *
	 * @param {Object} bindings
	 * @return {void}
	 */
	_addGlobalBindingsToVue(bindings) {
		for (let key in bindings) {
			const namespace = bindings[key]

			Object.defineProperty(Vue.prototype, key, {
				get() {
					return this.$app.use(namespace)
				},
			})
		}
	}
}
