import Calls from './Calls'
import Service from './Service'
import Insights from './Insights'
import Calendars from './Calendars'
import CallTypes from './CallTypes'
import Schedules from './Schedules'
import TimeBlocks from './TimeBlocks'
import ProviderGroup from './ProviderGroup'
import PartnerRequests from './PartnerRequests'

/**
 * Partners API Service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Partners extends Service {
	/**
	 * Create a new Service instance.
	 *
	 * @param {Request} request
	 * @param {Number?} id
	 */
	constructor(request, id) {
		super(request)

		/**
		 * The active partner id to scope child resource requests.
		 *
		 * @type {Number?}
		 */
		this.id = id || null
	}

	/**
	 * Find a model with the given id.
	 *
	 * @param {Number} id
	 * @return {Promise}
	 */
	find(id) {
		return this.request.get(`v4/partners/${id}`)
	}

	/**
	 * Get all the partners.
	 *
	 * @return {Promise}
	 */
	get() {
		return this.request.get('v4/partners')
	}

	/**
	 * Make a new Calendars resource instance.
	 *
	 * @return {Calendars}
	 */
	calendars() {
		return new Calendars(this.request)
	}

	/**
	 * Make a new Calls resource instance.
	 *
	 * @return {Calls}
	 */
	calls() {
		return new Calls(this.request, this.id)
	}

	/**
	 * Make a new CallTypes resource instance.
	 *
	 * @return {CallTypes}
	 */
	callTypes() {
		return new CallTypes(this.request)
	}

	/**
	 * Make a new ProviderGroup resource instance.
	 *
	 * @return {ProviderGroup}
	 */
	group() {
		return new ProviderGroup(this.request)
	}

	/**
	 * Make a new Insights resource instance.
	 *
	 * @return {Calendars}
	 */
	insights() {
		return new Insights(this.request)
	}

	/**
	 * Make a new PartnerRequests resource instance.
	 *
	 * @return {PartnerRequests}
	 */
	requests() {
		return new PartnerRequests(this.request, this.id)
	}

	/**
	 * Make a new Schedules resource instance.
	 *
	 * @return {Schedules}
	 */
	schedules() {
		return new Schedules(this.request, this.id)
	}

	/**
	 * Make a new TimeBlocks resource instance.
	 *
	 * @return {TimeBlocks}
	 */
	timeBlocks() {
		return new TimeBlocks(this.request)
	}

	/**
	 * Update the given partner's information.
	 *
	 * @param {Object} partner
	 * @return {Promise}
	 */
	update(partner) {
		return this.request.put(`v3/partners/${partner.id}`, { partner })
	}
}
