import Task from './Task'

/**
 * Load and initialize Pendo.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class InitializePendo extends Task {
	/**
	 * Run the asynchronous task and resolve when complete.
	 *
	 * @param {Function} next
	 * @return {void}
	 */
	async run(next) {
		if (this.shouldLoadPendo()) {
			this.loadPendo()

			const app = this.container.use('app')

			app.on('auth:success', this.onAuthSuccess)
		}

		next()
	}

	/**
	 * Get the Pendo API key from the environment.
	 *
	 * @return {String}
	 */
	getPendoApiKey() {
		return process.env.VUE_APP_PENDO_KEY
	}

	/**
	 * Determine if the application is in a production environment.
	 *
	 * @return {Boolean}
	 */
	isProduction() {
		return process.env.NODE_ENV === 'production'
	}

	/**
	 * Determine if the application should load Pendo.
	 *
	 * @return {Boolean}
	 */
	shouldLoadPendo() {
		if (!this.isProduction()) {
			return false
		}

		return !!this.getPendoApiKey()
	}

	/**
	 * Load Pendo into the application.
	 *
	 * @return {void}
	 */
	loadPendo() {
		;(function(apiKey) {
			;(function(p, e, n, d, o) {
				var v, w, x, y, z
				o = p[d] = p[d] || {}
				o._q = o._q || []
				v = [
					'initialize',
					'identify',
					'updateOptions',
					'pageLoad',
					'track',
				]
				for (w = 0, x = v.length; w < x; ++w)
					(function(m) {
						o[m] =
							o[m] ||
							function() {
								o._q[m === v[0] ? 'unshift' : 'push'](
									[m].concat([].slice.call(arguments, 0))
								)
							}
					})(v[w])
				y = e.createElement(n)
				y.async = !0
				y.src =
					'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js'
				z = e.getElementsByTagName(n)[0]
				z.parentNode.insertBefore(y, z)
			})(window, document, 'script', 'pendo')
		})(this.getPendoApiKey())
	}

	/**
	 * Initialize Pendo when the user logs in.
	 *
	 * @param {Object} app
	 * @return {void}
	 */
	onAuthSuccess(app) {
		const user = app.store.state.auth.user

		if (user) {
			pendo.initialize({
				visitor: {
					id: user.id,
					email: user.email,
					full_name: `${user.last_name}, ${user.first_name} `,
				},
				account: {
					id: user.id,
				},
			})
		}
	}
}
