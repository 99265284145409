/**
 * Service provider registrar.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Registrar {
	/**
	 * Create a new Registrar instance.
	 *
	 * @param {Container} container
	 */
	constructor(container) {
		/**
		 * The aliases to register within the IOC container.
		 *
		 * @type {Object}
		 */
		this.aliases = {}

		/**
		 * Check if the "boot" method has been called yet.
		 *
		 * @type {Boolean}
		 */
		this.booted = false

		/**
		 * The application's IOC container instance.
		 *
		 * @type {Container}
		 */
		this.container = container

		/**
		 * The registered service providers.
		 *
		 * @type {Array}
		 */
		this.providers = []

		/**
		 * Check if the "register" method has been called yet.
		 *
		 * @type {Boolean}
		 */
		this.registered = false
	}

	/**
	 * Make and push a new ServiceProvider instance to the providers array.
	 *
	 * @param {ServiceProvider} ServiceProvider
	 * @return {void}
	 */
	add(ServiceProvider) {
		this.providers.push(new ServiceProvider(this.container))
	}

	/**
	 * Boot each service provider & execute the container's drivers.
	 *
	 * @return {void}
	 */
	async boot() {
		await Promise.all(this._bootProviders())

		this.container.executeDrivers()

		this.booted = true
	}

	/**
	 * Call the "register" method on each ServiceProvider
	 * and register the app's container aliases.
	 *
	 * @return {void}
	 */
	register() {
		this._registerProviders()
		this._registerAliases()

		this.registered = true
	}

	/**
	 * Call the "register" method & then boot each ServiceProvider instance.
	 *
	 * @return {Promise}
	 */
	async registerAndBoot() {
		this.register()

		await this.boot()

		if (this.registered && this.booted) {
			this._afterRegisterAndBoot()

			return Promise.resolve(this)
		}

		return Promise.reject(this)
	}

	/**
	 * Set the aliases to be registered with the IOC container.
	 *
	 * @param {Object} aliases
	 * @return {void}
	 */
	setAliases(aliases) {
		this.aliases = {
			...this.aliases,
			...aliases,
		}
	}

	/**
	 * Add an array of ServiceProviders to be booted/registered.
	 *
	 * @param {ServiceProvider[]} providers
	 * @return {void}
	 */
	setProviders(providers) {
		providers.forEach(provider => this.add(provider))
	}

	/**
	 * Trigger garbage collection of the service provider instances.
	 *
	 * @return {void}
	 */
	_afterRegisterAndBoot() {
		this.aliases = {}
		this.providers = []
	}

	/**
	 * Boot each of the ServiceProvider instances.
	 *
	 * @return {Array}
	 */
	_bootProviders() {
		return this.providers
			.filter(provider => this._hasBootMethod(provider))
			.map(provider => provider.boot())
	}

	/**
	 * Check if a ServiceProvider instance has a "boot" method.
	 *
	 * @param {ServiceProvider} provider
	 * @return {Boolean}
	 */
	_hasBootMethod(provider) {
		return typeof provider.boot === 'function'
	}

	/**
	 * Check if a ServiceProvider instance has a "register" method.
	 *
	 * @param {ServiceProvider} provider
	 * @return {Boolean}
	 */
	_hasRegisterMethod(provider) {
		return typeof provider.register === 'function'
	}

	/**
	 * Register the given namespace aliases within the container.
	 *
	 * @param {String} namespace
	 * @param {String|Array} aliases
	 * @return {void}
	 */
	_registerAlias(namespace, aliases) {
		;[].concat(aliases).forEach(alias => {
			this.container.alias(namespace, alias)
		})
	}

	/**
	 * Register each namespace's aliases with the container.
	 *
	 * @return {void}
	 */
	_registerAliases() {
		Object.keys(this.aliases).forEach(ns =>
			this._registerAlias(ns, this.aliases[ns])
		)
	}

	/**
	 * Call the "register" method on each service provider.
	 *
	 * @return {void}
	 */
	_registerProviders() {
		this.providers
			.filter(provider => this._hasRegisterMethod(provider))
			.forEach(provider => provider.register())
	}
}
