import StateFactory from '@/store/helpers/StateFactory'

/**
 * The module's default state.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default function state() {
	const { partner_ids = [] } = StateFactory.getFromStorage('user', {})

	const fallbackPartnerId = partner_ids.length > 0 ? partner_ids[0] : null

	return {
		ids: [],
		items: {},
		active: StateFactory.getFromStorage('partner', fallbackPartnerId),
	}
}
