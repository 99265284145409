import moment from 'moment'
import { any, find } from '@/store/helpers/getters'

/**
 * The module's reactive state getters.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	any,
	find,

	/**
	 * Get all the calendars.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Array}
	 */
	all(state, getters) {
		if (!getters.any) {
			return []
		}

		return state.ids.map(id => state.items[id])
	},

	/**
	 * Get a calendar by partner id.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Array}
	 */
	getByPartner(state, getters) {
		return id => {
			return getters.all.filter(calendar => calendar.partner_id === id)
		}
	},

	/**
	 * Get all events by calendar and date.
	 *
	 * @param {Object} options.state
	 * @param {Function} options.getters
	 * @param {Object} options.rootState
	 * @return {Array}
	 */
	findEventsByCalendarAndDate(state, getters, rootState) {
		return (calendarId, startDate, endDate) => {
			const id = parseInt(calendarId)

			if (state.ids.indexOf(id) === -1) {
				return
			}

			if (!startDate || !endDate) {
				throw new Error('The start and end date must be specified.')
			}

			const end = moment.isMoment(endDate)
				? endDate
				: moment.utc(endDate, 'MM/DD/YYYY')

			return state.items[id].events.reduce((accu, eventId) => {
				const event = rootState.events.items[eventId]
				const timestamp = moment.utc(event.starts_at)

				if (timestamp.isSameOrBefore(end, 'day')) {
					accu.push(event)
				}

				return accu
			}, [])
		}
	},
}
