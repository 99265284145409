/**
 * Event dispatcher.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Dispatcher {
	/**
	 * Create a new Dispatcher instance.
	 *
	 * @param {Vue} bus
	 */
	constructor(bus) {
		/**
		 * The event bus / Vue instance.
		 *
		 * @type {Vue}
		 */
		this.vm = bus
	}

	/**
	 * Get all events & listeners from the event bus.
	 *
	 * @return {Object}
	 */
	all() {
		return this.vm._events
	}

	/**
	 * Get the total number of listeners for the
	 * given event or number of events w/ listeners.
	 *
	 * @param {?String} event
	 * @return {Number}
	 */
	count(event) {
		if (event) {
			return this.get(event).length
		}

		return this.events().reduce((count, event) => {
			return this.count(event) > 0 ? ++count : count
		}, 0)
	}

	/**
	 * Fire the given event(s) to all of the subscribed listeners.
	 *
	 * @param {String|String[]} events
	 * @param {?*} payload
	 * @return {Dispatcher}
	 */
	emit(events, payload) {
		if (this.has(events)) {
			typeof events === 'string'
				? this.vm.$emit(events, payload)
				: events.forEach(event => this.emit(event, payload))
		}

		return this
	}

	/**
	 * Get all event names w/ or w/out event listeners.
	 *
	 * @return {String[]}
	 */
	events() {
		return Object.keys(this.vm._events)
	}

	/**
	 * Get an event's listeners by event name.
	 *
	 * @param {String} event
	 * @return {Function[]}
	 */
	get(event) {
		return this.vm._events[event] || []
	}

	/**
	 * Check if an event(s) has/have any listeners.
	 *
	 * @param {String|String[]} events
	 * @return {Boolean}
	 */
	has(events) {
		if (typeof events === 'string') {
			return !!this.get(events).length
		}

		if (Array.isArray(events)) {
			return events.some(event => this.has(event))
		}

		return false
	}

	/**
	 * Remove all event listeners or a specific event listener callback.
	 *
	 * @param {String} event
	 * @param {Function} listener
	 * @return {Dispatcher}
	 */
	off(...args) {
		this.vm.$off(...args)

		return this
	}

	/**
	 * Listen for the given event(s) to be fired.
	 *
	 * @param {String|String[]} events
	 * @param {Function} listener
	 * @return {Dispatcher}
	 */
	on(events, listener) {
		this.vm.$on(events, listener)

		return this
	}

	/**
	 * Listen for an event once before removing itself.
	 *
	 * @param {String|String[]} events
	 * @param {Function} listener
	 * @return {Dispatcher}
	 */
	once(events, listener) {
		this.vm.$once(events, listener)

		return this
	}

	/**
	 * Remove all events containing the given string.
	 *
	 * @param {String} event
	 * @return {Dispatcher}
	 */
	remove(event) {
		this.events().forEach(eventName => {
			if (eventName.includes(event)) {
				this.off(eventName)
			}
		})

		return this
	}
}
