import Task from './Task'
import store from '@/store'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import Application from 'App/Foundation/Application'

/**
 * Get the API Client instance from the container.
 *
 * @return {Client}
 */
export const api = () => Application.getInstance().api()

/**
 * Establish a websocket connection once authenticated.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class EstablishWebSocketConnection extends Task {
	/**
	 * Run the asynchronous task and resolve when complete.
	 *
	 * @param {Function} next
	 * @return {void}
	 */
	async run(next) {
		window.Pusher = Pusher

		const app = this.container.use('App/Foundation/Application')

		app.on('auth:success', this.onAuthSuccess)

		app.on('partner:change', this.onPartnerChange)

		next()
	}

	/**
	 * Handle the user being successfully authenticated.
	 *
	 * @return {void}
	 */
	onAuthSuccess(app) {
		app.container.singleton('Echo', () => {
			const token = app.store.state.auth.session.access_token

			console.log('Establishing websocket connection.')

			const path = '/api/v3/pusher/authenticate'

			const authEndpoint = process.env.VUE_APP_API_URL
				? process.env.VUE_APP_API_URL + path
				: path

			return new Echo({
				auth: {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
				authEndpoint,
				broadcaster: 'pusher',
				key: process.env.VUE_APP_PUSHER_KEY,
				namespace: false,
			})
		})

		console.log(
			`Listening for events on WS channel: partner_${store?.getters?.partner?.id}`
		)

		app.use('Echo')
			.private(`partner_${store.getters.partner.id}`)
			.listen('new_call', event => app.emit('call:new', event))
			.listen('call_updated', event => app.emit('call:updated', event))

		console.log('Listening for OMD releases on WS channel: omd.release')

		app.use('Echo')
			.private('omd')
			.listen('App\\Events\\NewOmdRelease', event =>
				app.emit('omd:updated', event)
			)
	}

	/**
	 * Handle the active partner being changed.
	 *
	 * @param {Number} options.leave
	 * @return {void}
	 */
	onPartnerChange({ leave, subscribe }) {
		const app = Application.getInstance()

		if (leave) {
			console.log(`Leaving WS channel: partner_${leave}`)

			app.use('Echo').leave(`partner_${leave}`)
		}

		if (subscribe) {
			console.log(
				`Listening for events on WS channel: partner_${subscribe}`
			)

			app.use('Echo')
				.private(`partner_${subscribe}`)
				.listen('new_call', event => app.emit('call:new', event))
				.listen('call_updated', event =>
					app.emit('call:updated', event)
				)
		}
	}
}
