import moment from 'moment'

/**
 * Constant representing the admin role.
 *
 * @type {String}
 */
const ADMIN = 'admin'

/**
 * Constant representing the global-admin role.
 *
 * @var {String}
 */
const GLOBAL_ADMIN = 'global-admin'

/**
 * Constant representing the office manager role.
 *
 * @type {String}
 */
const OFFICE_MANAGER = 'office_manager'

/**
 * Constant representing the partner admin role.
 *
 * @type {String}
 */
const PARTNER_ADMIN = 'partner-admin'

/**
 * Constant representing the partner level roles.
 *
 * @type {Array}
 */
const PARTNER_ROLES = [OFFICE_MANAGER, PARTNER_ADMIN]

/**
 * The module's reactive state getters.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	/**
	 * Determine if a user is currently authenticated.
	 *
	 * @param {Object} state
	 * @return {Boolean}
	 */
	authenticated(state, getters, rootState, rootGetters) {
		return !!(
			moment()
				.add(state.session.expires_in || 0, 'seconds')
				.isAfter() && rootGetters.partner?.id
		)
	},

	/**
	 * Determine if the user has office manager access.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {Boolean}
	 */
	hasOfficeManagerAccess(state, getters) {
		return (
			getters.isAdmin ||
			getters.isGlobalAdmin ||
			getters.isOfficeManager ||
			getters.isPartnerAdmin
		)
	},

	/**
	 * Determine if the user has only office manager and partner admin access.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {Boolean}
	 */
	haslimitedManagerAccess(state, getters) {
		return getters.isOfficeManager || getters.isPartnerAdmin
	},

	/**
	 * Determine if the current user has the given role.
	 *
	 * @param {Object} state
	 * @return {Function}
	 */
	hasRole(state) {
		return (role, partner_id = null) => {
			if (!state.user || !Array.isArray(state.user.roles)) {
				return false
			}

			if (!PARTNER_ROLES.includes(role)) {
				return state.user.roles.some(model => model.name === role)
			}

			return state.user.roles.some(model => {
				return model.name === role && model.partner_id === partner_id
			})
		}
	},

	/**
	 * Determine if the user has admin role.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {Boolean}
	 */
	isAdmin(state, getters) {
		return getters.hasRole(ADMIN)
	},

	/**
	 * Determine if the user has platform monitoring role.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {Boolean}
	 */
	isGlobalAdmin(state, getters) {
		return getters.hasRole(GLOBAL_ADMIN)
	},

	/**
	 * Determine if the user has the office manager role.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @param {Object} rootState
	 * @param {Object} rootGetters
	 * @return {Boolean}
	 */
	isOfficeManager(state, getters, rootState, rootGetters) {
		return getters.hasRole(OFFICE_MANAGER, rootGetters.partner?.id)
	},

	/**
	 * Determine if the user has the partner admin role.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @param {Object} rootState
	 * @param {Object} rootGetters
	 * @return {Boolean}
	 */
	isPartnerAdmin(state, getters, rootState, rootGetters) {
		return getters.hasRole(PARTNER_ADMIN, rootGetters.partner?.id)
	},
}
