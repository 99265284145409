import Str from 'App/Support/Str'

/**
 * Base alert message.
 *
 * @author Erik Galloway <erik.galloway@ttec.com>
 */
export default class Message {
	/**
	 * Create a new Message instance.
	 *
	 * @param {String} type
	 * @param {String} title
	 * @param {?String} message
	 */
	constructor(type, title, message) {
		/**
		 * The message's body text.
		 *
		 * @type {String}
		 */
		this.body = message ? message : title

		/**
		 * The message's unique id/uuid.
		 *
		 * @type {String}
		 */
		this.id = Str.uuid()

		/**
		 * The message's unique timer id.
		 *
		 * @type {?Number}
		 */
		this.timer = null

		/**
		 * The message's title/prefix.
		 *
		 * @type {String}
		 */
		this.title = message ? title : ''

		/**
		 * The message's type.
		 *
		 * @type {String}
		 */
		this.type = type

		/**
		 * Determine if the message should be shown/visible.
		 *
		 * @type {Boolean}
		 */
		this.visible = false
	}

	/**
	 * Remove the timer id from the message.
	 *
	 * @return {Message}
	 */
	clearTimer() {
		if (this.hasTimer()) {
			clearTimeout(this.getTimer())
		}

		return this.setTimer(null)
	}

	/**
	 * Get the message body.
	 *
	 * @return {String}
	 */
	getBody() {
		return this.body
	}

	/**
	 * Get the message id/uuid.
	 *
	 * @return {String}
	 */
	getId() {
		return this.id
	}

	/**
	 * Get the message body (alias for getBody).
	 *
	 * @return {String}
	 */
	getMessage() {
		return this.getBody()
	}

	/**
	 * Get the timer id.
	 *
	 * @return {Number|null}
	 */
	getTimer() {
		return this.timer
	}

	/**
	 * Get the message title.
	 *
	 * @return {String}
	 */
	getTitle() {
		return this.title
	}

	/**
	 * Get the message type.
	 *
	 * @return {String}
	 */
	getType() {
		return this.type
	}

	/**
	 * Check if the message is visible.
	 *
	 * @return {Boolean}
	 */
	getVisible() {
		return this.visible
	}

	/**
	 * Check if a timer is currently set.
	 *
	 * @return {Boolean}
	 */
	hasTimer() {
		return !!this.timer
	}

	/**
	 * Check if the message is currently hidden (not visible).
	 *
	 * @return {Boolean}
	 */
	hidden() {
		return !this.getVisible()
	}

	/**
	 * Hide the message and mark it as not visible.
	 *
	 * @return {Message}
	 */
	hide() {
		this.visible = false

		return this
	}

	/**
	 * Set the timer id.
	 *
	 * @param {?String} timer
	 * @return {Message}
	 */
	setTimer(timer = null) {
		this.timer = timer

		return this
	}

	/**
	 * Show the message,
	 *
	 * @return {Message}
	 */
	show() {
		this.visible = true

		return this
	}

	/**
	 * Check if the message is showing/visible.
	 *
	 * @return {Boolean}
	 */
	showing() {
		return this.getVisible()
	}
}
