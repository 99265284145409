import Text from './Definitions/Text'
import Style from './Definitions/Style'
import Table from './Definitions/Table'
import Columns from './Definitions/Columns'
import TextWrapper from './Definitions/TextWrapper'

/**
 * PDF builder (pdfMake wrapper).
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Builder {
	/**
	 * Make a new Builder instance.
	 *
	 * @param {pdfMake} pdfMake
	 */
	constructor(pdfMake) {
		/**
		 * The custom style definitions.
		 *
		 * @type {Array}
		 */
		this.styles = []

		/**
		 * The PDF's content definition objects.
		 */
		this.content = []

		/**
		 * The pdfMake instance used to generate the PDF file.
		 *
		 * @type {pdfMake}
		 */
		this.pdf = pdfMake
	}

	/**
	 * Add columns to pdf.
	 *
	 * @return {Columns}
	 */
	addColumns() {
		const columns = new Columns()

		return this.pushContent(columns)
	}

	/**
	 * Get the compiled content definition object.
	 *
	 * @return {Array}
	 */
	buildContent() {
		return this.content.map(content => content.toObject())
	}

	/**
	 * Get the compiled style definition object.
	 *
	 * @return {Object}
	 */
	buildStyles() {
		return this.styles.reduce((accu, style) => {
			accu[style.getName()] = style.toObject()

			return accu
		}, {})
	}

	/**
	 * Create a new pdf file.
	 *
	 * @return {pdfMake}
	 */
	create() {
		return this.pdf.createPdf({
			content: this.buildContent(),
			styles: this.buildStyles(),
		})
	}

	/**
	 * Add a new style.
	 *
	 * @param {String} name
	 * @return {Style}
	 */
	defineStyle(name) {
		const style = new Style(name)

		this.styles.push(style)

		return style
	}

	/**
	 * Create the defined PDF and download the file.
	 *
	 * @return {void}
	 */
	download(fileName) {
		this.create().download(fileName)
	}

	/**
	 * Get a content object by index.
	 *
	 * @return {*}
	 */
	get(index) {
		return this.content[index]
	}

	/**
	 * Preview the compiled PDF data object.
	 *
	 * @return {Object}
	 */
	preview() {
		return {
			content: this.buildContent(),
			styles: this.buildStyles(),
		}
	}

	/**
	 * Add a new item to the pdf's content.
	 *
	 * @param {*} item
	 * @return {*}
	 */
	pushContent(item) {
		this.content.push(item)

		return item
	}

	/**
	 * Add a new table to the PDF's content.
	 *
	 * @return {Table}
	 */
	table(widths = []) {
		const table = new Table(widths)

		return this.pushContent(table)
	}

	/**
	 * Add text to the content.
	 *
	 * @param {String} value
	 * @return {Text}
	 */
	text(value = '') {
		const text = new Text(value)

		return this.pushContent(text)
	}

	/**
	 * Add a new text wrapper to the PDF's content.
	 *
	 * @return {TextWrapper}
	 */
	textWrapper() {
		const wrapper = new TextWrapper()

		return this.pushContent(wrapper)
	}
}
