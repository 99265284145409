import Auth from './Auth'
import Calls from './Calls'
import Notes from './Notes'
import Users from './Users'
import Events from './Events'
import Partners from './Partners'
import Password from './Password'
import Calendars from './Calendars'
import CallTypes from './CallTypes'
import Providers from './Providers'
import EventTypes from './EventTypes'
import EventExceptions from './EventExceptions'
import MessagingProfiles from './MessagingProfiles'

/**
 * The API client services.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default {
	auth: Auth,
	calendars: Calendars,
	calls: Calls,
	callTypes: CallTypes,
	events: Events,
	eventExceptions: EventExceptions,
	eventTypes: EventTypes,
	messagingProfiles: MessagingProfiles,
	notes: Notes,
	partners: Partners,
	password: Password,
	providers: Providers,
	users: Users,
}
