import Client from './Client'
import Manager from './Manager'
import Services from './Services'
import ServiceProvider from 'App/Support/ServiceProvider'

/**
 * Api service provider.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class ApiProvider extends ServiceProvider {
	/**
	 * Register bindings in the container.
	 *
	 * @return {void}
	 */
	register() {
		this._registerManager()
		this._registerClient()
	}

	/**
	 * Register the API client.
	 *
	 * @return {void}
	 */
	_registerClient() {
		this.app.bind('App/Api/Client', ioc => {
			return new Client(ioc.use('App/Api/Manager'))
		})
	}

	/**
	 * Register the API Manager instance.
	 *
	 * @return {void}
	 */
	_registerManager() {
		this.app.singleton('App/Api/Manager', ioc => {
			return new Manager(ioc, Services)
		})

		this.app.manager('App/Api/Manager', this.app.use('App/Api/Manager'))
	}
}
