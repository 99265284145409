import { any, find } from '@/store/helpers/getters'

/**
 * The module's reactive state getters.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	any,
	find,

	/**
	 * Get the event type by name.
	 *
	 * @param {Object} state
	 * @return {Object}
	 *
	 */
	findByName(state) {
		return name => {
			const type = state.ids.find(id => {
				return state.items[id].name === name
			})

			return type ? state.items[type] : null
		}
	},
}
