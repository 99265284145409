import store from '@/store'
import router from '@/router'
import VueRouter from 'vue-router'
import Status from 'App/Http/Status'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

/*
|--------------------------------------------------------------------------
| Axios interceptors
|--------------------------------------------------------------------------
|
| Global Axios interceptors run before a request is sent and can transform
| a response once it is received.
|
*/

/**
 * The API endpoints (w/out base url - api/v1) that should not have
 * an Authorization header & bearer token attached to the request.
 *
 * @type {Array}
 */
const excluded = ['login']

/**
 * Axios global request interceptors.
 *
 * @type {Array}
 */
const request = [
	/**
	 * Attach the authenticated bearer token's to the outgoing request.
	 *
	 * @param {Object} config
	 * @return {Object}
	 */
	config => {
		const { access_token } = store.state.auth.session

		if (access_token && !excluded.includes(config.url)) {
			config.headers.Authorization = `Bearer ${access_token}`
		}

		return config
	},
]

/**
 * Handle an unauthenticated error response  from the API.
 *
 @return {void}
 */
const unauthenticatedErrorHandler = async () => {
	await store.dispatch('auth/logout')

	if (router.currentRoute.name === 'login') {
		return
	}

	const { isNavigationFailure, NavigationFailureType } = VueRouter

	try {
		await router.replace({ name: 'login' })
	} catch (failure) {
		const hasFailed = isNavigationFailure(
			failure,
			NavigationFailureType.createRouterError
		)

		if (hasFailed) {
			console.log('The session has expired.')
		}
	}
}

/**
 * Axios global response interceptors.
 *
 * @type {Array}
 */
const response = [
	{
		/**
		 * Success response interceptor.
		 *
		 * @param {Object} response
		 * @return {Object}
		 */
		success: response => {
			const userRoles = get(response, 'data.data.roles', [])
			const url = get(response, 'config.url', '')

			// Restrict some user role to login into OMD,
			// until we get permission based login support in API
			if (url === 'v4/auth/login') {
				const restrictedRole = [
					'transcriptionist',
					'transcriptionist-manager',
					'transcriptionist-qa',
				]
				const allowedRoles = []
				userRoles.forEach(({ name }) => {
					if (!restrictedRole.includes(name)) {
						allowedRoles.push(name)
					}
				})
				if (!isEmpty(userRoles) && isEmpty(allowedRoles)) {
					return {
						status: 401,
					}
				}
			}
			return response
		},

		/**
		 * Error response interceptor.
		 *
		 * @param {Error} error
		 * @return {Promise}
		 */
		error: async error => {
			if (error?.response?.status === Status.UNAUTHENTICATED) {
				await unauthenticatedErrorHandler()
			}

			return Promise.reject(error)
		},
	},
]

export default {
	request,
	response,
}
