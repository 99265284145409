import Task from './Task'

/**
 * Resolve the options to pass to the root Vue instance.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class ResolveVueOptions extends Task {
	/**
	 * Run the asynchronous task and resolve when complete.
	 *
	 * @param {Function} next
	 * @return {void}
	 */
	async run(next) {
		const app = this.container.use('App/Foundation/Application')

		// if (!this.isProduction()) {
		window.$app = app
		// }

		next({
			app: app,
			store: app.store,
			router: app.router,
		})
	}
}
