import Service from './Service'

/**
 * MessagingProfiles API service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class MessagingProfiles extends Service {
	/**
	 * Get all requests by partner from the API.
	 *
	 * @param {Number?} user
	 * @return {Promise}
	 */
	get(user) {
		return this.request.get(`v5/users/${user}/messaging-profiles`)
	}
}
