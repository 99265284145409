import Service from './Service'
import { FULL_VISIBILITY_FILTER, statuses } from '@/config/calls'

/**
 * Call types API service.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class CallTypes extends Service {
	/**
	 * Get the number of incomplete call types w/ the specified filters.
	 *
	 * @param {Boolean} fullVisibility
	 * @return {Promise}
	 */
	count(type, fullVisibility = true) {
		if (fullVisibility) {
			this.withFullVisibility()
		}

		this.withAllStatuses()

		return this.request.get(
			`v3/call-types/${type}/calls/count/incomplete`,
			this.getOptions()
		)
	}

	/**
	 * Get all call types from the API.
	 *
	 * @param {Number?} partner
	 * @return {Promise}
	 */
	get(partner) {
		return this.request.get(`v4/partners/${partner}/call-types`)
	}

	/**
	 * Set the partner query param to scope the query.
	 *
	 * @param {Number} id
	 * @return {CallTypes}
	 */
	partner(id) {
		return this.setQueryParam('partner', id)
	}

	/**
	 * Add all statuses to the statuses query scope parameter.
	 *
	 * @return {CallTypes}
	 */
	withAllStatuses() {
		return this.pushQueryParam(
			'statuses',
			statuses.map(status => status.name)
		)
	}

	/**
	 * Add the full visibility query scope filter.
	 *
	 * @return {CallTypes}
	 */
	withFullVisibility() {
		return this.pushQueryParam('filters', FULL_VISIBILITY_FILTER)
	}

	/**
	 * Set the start time query scope parameter.
	 *
	 * @param {String} starts
	 * @return {Calls}
	 */
	starts(starts = null) {
		return this.setQueryParam('starts', starts)
	}

	/**
	 * Set the ends time query scope parameter.
	 *
	 * @param {String} ends
	 * @return {Calls}
	 */
	ends(ends = null) {
		return this.setQueryParam('ends', ends)
	}
}
