import { any, find } from '@/store/helpers/getters'

/**
 * The module's reactive state getters.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	any,
	find,

	/**
	 * Get the active profile.
	 *
	 * @param   {Object}  state
	 * @param   {Object}  getters
	 * @param   {Object}  rootState
	 *
	 * @return  {MessagingProfile}
	 */
	activeProfile(state, getters, rootState) {
		const active = state.ids.find(id => {
			return state.items[id].partner_id === rootState.partners.active
		})

		return state.items[active]
	},

	/**
	 * Get all the messagingProfiles.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Array}
	 */
	all(state, getters) {
		if (!getters.any) {
			return []
		}

		return state.ids.map(id => state.items[id])
	},

	/**
	 * Determine if the active partner has a messaging profile.
	 *
	 * @param   {Object}  state
	 * @param   {Object}  getters
	 *
	 * @return  {Boolean}
	 */
	isActiveProfile(state, getters) {
		return !!getters.activeProfile
	},
}
