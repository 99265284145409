import {
	FETCHING,
	FETCHING_COMPLETE,
	LOADING,
	LOADING_COMPLETE,
	LOCKED,
	UNLOCK,
} from '@/store/mutation-types'

/**
 * The module's state mutators.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default {
	/**
	 * Update the fetching loader's status and message.
	 *
	 * @param {Object} state
	 * @param {String} message
	 * @return {void}
	 */
	[FETCHING](state, message) {
		state.fetching.status = true
		state.fetching.message = message || ''
	},

	/**
	 * Mark the fetching loader as complete.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[FETCHING_COMPLETE](state) {
		state.fetching.status = false
		state.fetching.message = ''
	},

	/**
	 * Update the page loader's status and message.
	 *
	 * @param {Object} state
	 * @param {String} message
	 * @return {void}
	 */
	[LOADING](state, message) {
		state.page.status = true
		state.page.message = message || ''
	},

	/**
	 * Mark the page loader as complete.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[LOADING_COMPLETE](state) {
		state.page.status = false
		state.page.message = ''
	},

	/**
	 * Update the locked status and message.
	 *
	 * @param {Object} state
	 * @param {String} message
	 * @return {void}
	 */
	[LOCKED](state, message) {
		state.locked.status = true
		state.locked.message = message || ''
	},

	/**
	 * Unlock the application's status and message.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[UNLOCK](state) {
		state.locked.status = false
		state.locked.message = ''
	},
}
