import stateFactory from './state'
import * as helpers from '@/store/helpers'
import {
	ADD_ITEMS,
	ADD_EVENT_EXCEPTION,
	REMOVE_BY_ID,
	RESET,
	REMOVE_EVENT_EXCEPTION,
} from '@/store/mutation-types'

/**
 * The module's state mutators.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	/**
	 * Add the given model(s) to the store.
	 *
	 * @param {Object} state
	 * @param {Array} models
	 */
	[ADD_ITEMS](state, models) {
		helpers.add(state, models)
	},

	/**
	 * Add the given exception to the store.
	 *
	 * @param {Object} state
	 * @param {Object} exception
	 *
	 * @return {void}
	 */
	[ADD_EVENT_EXCEPTION](state, exception) {
		state.items[exception.event_id].exceptions.push(exception)
	},

	/**
	 * Remove a model from the module by id.
	 *
	 * @param {Object} state
	 * @param {Number} id
	 * @return {void}
	 */
	[REMOVE_BY_ID](state, id) {
		helpers.removeById(state, id)
	},

	/**
	 * Remove event exception.
	 *
	 * @param {Object} state
	 * @param {Number} event
	 * @param {Number} exception
	 * @return {void}
	 */
	[REMOVE_EVENT_EXCEPTION](state, { event, exception }) {
		const index = state.items[event].exceptions.findIndex(
			item => item.id === exception
		)

		if (index > -1) {
			state.items[event].exceptions.splice(index, 1)
		}
	},

	/**
	 * Reset the given state back to its original value.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[RESET](state) {
		helpers.reset(state, stateFactory())
	},
}
