/**
 * The application's IOC container.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Container {
	/**
	 * Create a new Container instance.
	 */
	constructor() {
		/**
		 * The application's namespace aliases.
		 *
		 * @type {Object}
		 */
		this.aliases = {}

		/**
		 * The application's resolvable namespace bindings.
		 *
		 * @type {Object}
		 */
		this.bindings = {}

		/**
		 * The drivers that extend a manager/implement an interface.
		 *
		 * @type {Array}
		 */
		this.drivers = []

		/**
		 * The mocks to be resolved during testing.
		 *
		 * @type {Object}
		 */
		this.fakes = {}

		/**
		 * The managers that can be extended/implemented by drivers.
		 *
		 * @type {Object}
		 */
		this.managers = {}
	}

	/**
	 * Add a namespace alias to the container.
	 *
	 * @param {String} namespace
	 * @param {String} alias
	 * @return {void}
	 */
	alias(namespace, alias) {
		this.aliases[alias] = namespace
	}

	/**
	 * Bind a namespace to a resolvable function.
	 *
	 * @param {String} namespace
	 * @param {Function} resolver
	 * @param {Boolean} singleton
	 * @return {void}
	 */
	bind(namespace, resolver, singleton = false) {
		if (!this._isFunction(resolver)) {
			throw new Error(
				`The binding resolver must be a function [${namespace}]`
			)
		}

		this.bindings[namespace] = {
			resolver,
			singleton,
			cached: null,
		}
	}

	/**
	 * Resolve each of the drivers to their manager.
	 *
	 * @return {void}
	 */
	executeDrivers() {
		this.drivers = this.drivers.filter(driver => {
			let [namespace, key, resolver, ...options] = driver

			if (!this._isFunction(resolver)) {
				throw new Error(
					`The driver resolver must be a function [${namespace}][${key}]`
				)
			}

			this.managers[namespace].extend(key, resolver(this), ...options)

			return false
		})
	}

	/**
	 * Extend a binding by calling extend on the registered driver manager.
	 *
	 * @param {String} namespace
	 * @param {String} key
	 * @param {Function} closure
	 * @param {...*} [options]
	 * @return {void}
	 */
	extend(...args) {
		this.drivers.push(args)
	}

	/**
	 * Add a mock to the application's container.
	 *
	 * @param {String} namespace
	 * @param {Function} resolver
	 * @param {Boolean} singleton
	 */
	fake(namespace, resolver, singleton = false) {
		if (!this._isFunction(resolver)) {
			throw new Error(
				`The fake resolver must be a function [${namespace}]`
			)
		}

		this.fakes[namespace] = {
			resolver,
			singleton,
			cached: null,
		}
	}

	/**
	 * Get the container's aliases.
	 *
	 * @return {Object}
	 */
	getAliases() {
		return { ...this.aliases }
	}

	/**
	 * Get the container's bindings.
	 *
	 * @return {Object}
	 */
	getBindings() {
		return { ...this.bindings }
	}

	/**
	 * Get the container's binding mocks/fakes.
	 *
	 * @return {Object}
	 */
	getFakes() {
		return { ...this.fakes }
	}

	/**
	 * Get the container's extendable managers.
	 *
	 * @return {Object}
	 */
	getManagers() {
		return { ...this.managers }
	}

	/**
	 * Check if a mock/fake exists for the given namespace.
	 *
	 * @param {String} namespace
	 * @return {Boolean}
	 */
	hasFake(namespace) {
		return !!this.fakes[namespace]
	}

	/**
	 * Check if an alias exists.
	 *
	 * @param {String} alias
	 * @return {Boolean}
	 */
	isAlias(alias) {
		return !!this.aliases[alias]
	}

	/**
	 * Check if a namespace is a resolvable binding.
	 *
	 * @param {String} namespace
	 * @return {Boolean}
	 */
	isBinding(namespace) {
		return !!this.bindings[namespace]
	}

	/**
	 * Make or resolve an instance.
	 *
	 * @param {String|Function} namespace
	 * @param {...mixed} params
	 * @return {Object}
	 */
	make(namespace, ...params) {
		if (this._isString(namespace)) {
			return this.use(namespace, ...params)
		}

		return this.makeInstance(namespace, ...params)
	}

	/**
	 * Make a new instance of the given class.
	 *
	 * @example
	 * ```
	 * class Foo {
	 *   static get inject () {
	 *     return ['App/Bar']
	 *   }
	 *
	 *   constructor (Bar) {
	 *     this.bar = Bar
	 *   }
	 * }
	 *
	 * Container.makeInstance(Foo)
	 * ```
	 * @param {Function} Obj
	 * @param {Array} params
	 * @return {Object}
	 */
	makeInstance(Obj, ...params) {
		if (!this._isClass(Obj)) {
			return Obj
		}

		if (!Array.isArray(params)) {
			params = []
		}

		const injections = (Obj.inject || []).map(injection => {
			return this.make(injection)
		})

		params = [...injections, ...params]

		return new Obj(...params)
	}

	/**
	 * Add an extendable manager to the container.
	 *
	 * @param {String} namespace
	 * @param {Function} bindingInterface
	 * @return {void}
	 */
	manager(namespace, bindingInterface) {
		if (!this._isFunction(bindingInterface.extend)) {
			throw new Error(
				`An extendable manager [${namespace}] must be extendable.`
			)
		}

		this.managers[namespace] = bindingInterface
	}

	/**
	 * Resolve a binding from the container.
	 *
	 * @param {String} namespace,
	 * @param {Array} args
	 * @return {Object}
	 */
	resolveBinding(namespace, args) {
		if (!this.isBinding(namespace)) {
			throw new Error(`The binding [${namespace}] could not be found.`)
		}

		const binding = this.bindings[namespace]

		if (!this._isArray(args)) {
			args = args ? [args] : []
		}

		if (!binding.singleton) {
			return binding.resolver(this, ...(args || []))
		}

		if (!binding.cached) {
			binding.cached = binding.resolver(this, ...(args || []))
		}

		return binding.cached
	}

	/**
	 * Resolve a fake from the container.
	 *
	 * @param {String} namespace
	 * @param {Array} args
	 * @return {Object}
	 */
	resolveFake(namespace, args) {
		if (!this.hasFake(namespace)) {
			throw new Error(`The binding fake [${namespace}] does not exist.`)
		}

		const fake = this.fakes[namespace]

		if (!this._isArray(args)) {
			args = args ? [args] : []
		}

		if (!fake.singleton) {
			return fake.resolver(this, ...args)
		}

		if (!fake.cached) {
			fake.cached = fake.resolver(this, ...args)
		}

		return fake.cached
	}

	/**
	 * Add a singleton binding to the container.
	 *
	 * @param {String} namespace
	 * @param {Function} resolver
	 * @return {void}
	 */
	singleton(namespace, resolver) {
		this.bind(namespace, resolver, true)
	}

	/**
	 * Add a singleton fake binding to the container.
	 *
	 * @param {String} namespace
	 * @param {Function} resolver
	 * @return {void}
	 */
	singletonFake(namespace, resolver) {
		this.fake(namespace, resolver, true)
	}

	/**
	 * Attempt to resolve a namespace in the following order.
	 *
	 * 1. Look for a registered fake/mock for the namespace.
	 * 2. Look for a registered binding for the namespace.
	 * 3. Look for an alias and if found repeat step #1.
	 *
	 * @param {String} namespace
	 * @param {...mixed} args
	 * @return {Object}
	 * @throws {Error}
	 */
	use(namespace, ...args) {
		if (this.hasFake(namespace)) {
			return this.resolveFake(namespace, args)
		}

		if (this.isBinding(namespace)) {
			return this.resolveBinding(namespace, args)
		}

		if (this.isAlias(namespace)) {
			return this.use(this.aliases[namespace], ...args)
		}

		throw new Error(`The namespace [${namespace}] could not be resolved.`)
	}

	/**
	 * Check if an object is an array.
	 *
	 * @param {Array} arr
	 * @return {Boolean}
	 */
	_isArray(arr) {
		return Array.isArray(arr)
	}

	/**
	 * Check if the given item is an ES6 class.
	 *
	 * @param {mixed} fn
	 * @return {Boolean}
	 */
	_isClass(fn) {
		return !!(
			this._isFunction(fn) &&
			fn.prototype &&
			fn.prototype.constructor.name
		)
	}

	/**
	 * Check if the given item is a function.
	 *
	 * @param {mixed} fn
	 * @return {Boolean}
	 */
	_isFunction(fn) {
		return typeof fn === 'function'
	}

	/**
	 * Check if the given item is a string.
	 *
	 * @param {mixed} str
	 * @return {Boolean}
	 */
	_isString(str) {
		return typeof str === 'string'
	}
}
