import actions from '@/store/helpers/actions'
import {
	SET_EXTERNAL_PROVIDERS,
	SET_INTERNAL_PROVIDERS,
} from '@/store/mutation-types'

/**
 * The module's dispatchable actions.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	...actions,

	/**
	 * Set the providers depending on their type.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Array} providers
	 * @return {Response}
	 */
	async set({ commit, dispatch }, providers) {
		const internal = []
		const external = []

		providers.forEach(provider => {
			if (provider.type === 'internal') {
				return internal.push(provider.id)
			}

			external.push(provider.id)
		})

		await dispatch('add', providers)

		commit(SET_INTERNAL_PROVIDERS, internal)

		commit(SET_EXTERNAL_PROVIDERS, external)
	},
}
