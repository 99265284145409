import Auth from './Auth'
import ServiceProvider from 'App/Support/ServiceProvider'

/**
 * Authentication service provider.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class AuthServiceProvider extends ServiceProvider {
	/**
	 * Boot the application's registered services.
	 *
	 * @return {void}
	 */
	async boot() {
		const auth = this.app.use('App/Auth/Auth')

		if (process.env.NODE_ENV === 'test') {
			return (auth._booted = true)
		}

		await auth.boot()
	}

	/**
	 * Register bindings in the container.
	 *
	 * @return {void}
	 */
	register() {
		this.app.singleton('App/Auth/Auth', container => {
			const app = container.use('App/Foundation/Application')

			return new Auth(app)
		})
	}
}
