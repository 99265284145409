/**
 * Determine if any models exist in the module.
 *
 * @param {Object} state
 * @return {Boolean}
 */
export const any = state => state.ids.length > 0

/**
 * Get the total number of models in the module.
 *
 * @param {Object} state
 * @return {Number}
 */
export const count = state => state.ids.length

/**
 * Find a model by id.
 *
 * @param {Object} state
 * @return {Function}
 */
export const find = state => {
	return identifier => {
		const id = parseInt(identifier)

		if (id && state.ids.indexOf(id) > -1) {
			return state.items[id]
		}

		return null
	}
}

/**
 * Map the given module's state to entity array.
 *
 * @param {Object} state The entity module state to map.
 * @return {Array}
 */
export const mapToArray = state => {
	return state.ids.map(id => state.items[id])
}
