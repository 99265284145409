import StateFactory from '@/store/helpers/StateFactory'

/**
 * The module's default state.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default function state() {
	return StateFactory.paginatedEntity({ name: null, last_name: null })
}
