import Service from './Service'
import EventRequests from './EventRequests'

/**
 * Events API Service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Events extends Service {
	/**
	 * Create a new event.
	 *
	 * @param {Number} calendar
	 * @param {Number} event.duration
	 * @param {Number} event.event_type_id
	 * @param {Number} event.recurrence
	 * @param {String} event.starts_at
	 * @param {Array} event.eventables
	 * @return {Promise}
	 */
	create(calendar, event) {
		return this.request.post(`v4/calendars/${calendar}/events`, event)
	}

	/**
	 * Delete the given event.
	 *
	 * @param {Number} id
	 * @return {Promise}
	 */
	delete(calendar, id) {
		return this.request.delete(`v4/calendars/${calendar}/events/${id}`)
	}

	/**
	 * Set the end date query scope parameter.
	 *
	 * @param {String} date
	 * @return {Events}
	 */
	end(date) {
		return this.setQueryParam('ends_at', date)
	}

	/**
	 * Set the expanced query scope parameter.
	 *
	 * @param {Number} expanded
	 * @return {Events}
	 */
	expanded(expanded = 1) {
		return this.setQueryParam('expanded', expanded)
	}

	/**
	 * Find an event calendar id by event id.
	 *
	 * @param {Number} calendar
	 * @param {Number} id
	 * @return {Promise}
	 */
	find(calendar, id) {
		return this.request.get(`v4/calendars/${calendar}/events/${id}`)
	}

	/**
	 * Get all the calendar's events.
	 *
	 * @param {Number} calendar
	 * @return {Promise}
	 */
	get(calendar) {
		return this.request.get(
			`v4/calendars/${calendar}/events`,
			this.getOptions()
		)
	}

	/**
	 * Make a new EventRequests resource instance.
	 *
	 * @return {EventRequests}
	 */
	requests() {
		return new EventRequests(this.request)
	}

	/**
	 * Set the start date query scope parameter.
	 *
	 * @param {String} date
	 * @return {Events}
	 */
	start(date) {
		return this.setQueryParam('starts_at', date)
	}

	/**
	 * Update an event.
	 *
	 * @param {Number} calendar
	 * @param {Number} id
	 * @param {Number} event.duration
	 * @param {Number} event.event_type_id
	 * @param {Number} event.recurrence
	 * @param {String} event.starts_at
	 * @param {Array} event.eventables
	 * @return {Promise}
	 */
	update(calendar, id, event) {
		return this.request.put(`v4/calendars/${calendar}/events/${id}`, event)
	}
}
