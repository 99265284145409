import { any, find } from '@/store/helpers/getters'

/**
 * The module's reactive state getters.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	any,
	find,

	/**
	 * Get all time blocks belonging to a specific calendar id.
	 *
	 * @param {Object} state
	 * @return {Function}
	 */
	getByCalendarId(state) {
		return calendarId => {
			const id = parseInt(calendarId)

			if (!id) {
				return []
			}

			return state.ids.reduce((accu, blockId) => {
				const timeblock = state.items[blockId]

				if (timeblock.calendars.some(calendar => calendar.id === id)) {
					accu.push(timeblock)
				}

				return accu
			}, [])
		}
	},
}
