import Engine from './Engine'
import { container } from 'App/Foundation'
import Registrar from 'App/Foundation/Registrar'

/**
 * The application's bootable kernel.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Kernel {
	/**
	 * Indicates if the application has been installed as a Vue plugin.
	 *
	 * @type {Boolean}
	 */
	static installed = false

	/**
	 * Boot the application's modules.
	 *
	 * @return {Object}
	 */
	static async boot() {
		if (this.usesAccessibilityTools()) {
			this.loadAccessibilityTools()()
		}

		const registrar = new Registrar(container)

		return await new Engine(registrar).start()
	}

	/**
	 * Get the current node environment or an env variable by name.
	 *
	 * @param {String} key
	 * @return {String}
	 */
	static env(key = 'NODE_ENV') {
		return process.env[key]
	}

	/**
	 * Install the application as a Vue plugin.
	 *
	 * @param {Vue} Vue
	 * @return {void}
	 */
	static install(Vue) {
		if (this.installed) {
			return
		}

		this.installing()

		/**
		 * Allow the Application instance to be accessed
		 * inside of a Vue component by calling this.$app.
		 */
		Object.defineProperty(Vue.prototype, '$app', {
			get() {
				return this.$root._app
			},
		})

		Vue.mixin({
			beforeCreate() {
				if (this.$options.app) {
					this._app = this.$options.app
				}
			},
		})
	}

	/**
	 * Mark the application as installed.
	 *
	 * @return {void}
	 */
	static installing() {
		this.installed = true
	}

	/**
	 * Determine if we are in a local environment.
	 *
	 * @return {Boolean}
	 */
	static isLocalEnv() {
		return ['local', 'development'].includes(this.env())
	}

	/**
	 * Determine if we are in a production environment.
	 *
	 * @return {Boolean}
	 */
	static isProductionEnv() {
		return this.env() === 'production'
	}

	/**
	 * Load the accessibility/508 compliance tools.
	 *
	 * @return {Function}
	 */
	static loadAccessibilityTools() {
		return () => import('@khanacademy/tota11y')
	}

	/**
	 * Determine if the accessibility tools should be loaded.
	 *
	 * @return {Boolean}
	 */
	static usesAccessibilityTools() {
		return !!this.env('VUE_APP_ENABLE_508_TOOLS')
	}

	/**
	 * Determine if Vue devtools should be enabled.
	 *
	 * @return {Boolean}
	 */
	static usesVueDevTools() {
		return !!(this.env('VUE_APP_DEV_TOOLS') || this.isLocalEnv())
	}
}
