import Service from './Service'

/**
 * Event requests API Service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class EventRequests extends Service {
	/**
	 * Get all the event's cover my call requests.
	 *
	 * @param {Number} eventId
	 * @return {Promise}
	 */
	get(eventId) {
		return this.request.get(`v4/events/${eventId}/requests`)
	}
}
