/**
 * Module state factory builder.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class StateFactory {
	/**
	 * Make the base state for an entity.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static entity(state = {}) {
		return {
			...state,
			ids: [],
			items: {},
		}
	}

	/**
	 * Get the given key from local storage.
	 *
	 * @param {String} key
	 * @param {*} defaultValue
	 * @return {Object}
	 */
	static getFromStorage(key, defaultValue = {}) {
		const data = localStorage.getItem(key)

		if (data) {
			return JSON.parse(data)
		}

		return defaultValue
	}

	/**
	 * Make the paginated state for an entity.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static paginatedEntity(state = {}) {
		return this.entity({
			...state,
			meta: this.getMetaState(),
		})
	}

	/**
	 * Get a paginated meta state object.
	 *
	 * @return {Object}
	 */
	static getMetaState() {
		return {
			current_page: 0,
			from: 0,
			last_page: 1,
			offset: 0,
			per_page: 50,
			to: 0,
			total: 0,
		}
	}
}
