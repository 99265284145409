import Text from './Text'

/**
 * TableRow definition object.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class TableRow {
	/**
	 * Make a new TableRow instance.
	 */
	constructor() {
		this.columns = []
	}

	/**
	 * Add text to the row.
	 *
	 * @param {String} value
	 * @param {String} style
	 * @return {Text}
	 */
	text(value, style = null) {
		const text = new Text(value, style)

		this.columns.push(text)

		return text
	}

	/**
	 * Get the instance as an array.
	 *
	 * @return {Array}
	 */
	toArray() {
		return this.columns.map(column => column.toObject())
	}
}
