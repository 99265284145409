<template>
	<div class="snackbar-container">
		<alert-message
			v-for="message in alerts"
			:key="message.id"
			v-bind.sync="message"
		/>
	</div>
</template>
<script>
import AlertMessage from './Message.vue'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		AlertMessage,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get all alerts as an array.
		 *
		 * @return {Array}
		 */
		alerts() {
			return this.ids.map(id => this.messages[id])
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Find an alert by id.
		 *
		 * @param {String} id
		 * @return {Message|null}
		 */
		getMessage(id) {
			return this.messages[id]
		},

		/**
		 * Check if the message with the given id exists.
		 *
		 * @param {String} id
		 * @return {Boolean}
		 */
		hasMessage(id) {
			return !!this.getMessage(id)
		},

		/**
		 * Hide an alert by id.
		 *
		 * @param {String} id
		 * @return {Vue}
		 */
		hide(id) {
			if (this.hasMessage(id)) {
				this.getMessage(id)
					.clearTimer()
					.hide()

				this.$forceUpdate()

				this.startRemoveTimer(id)
			}

			return this
		},

		/**
		 * Set a timeout to autohide the alert after 5s by id.
		 *
		 * @param {String} id
		 * @return {Number}
		 */
		makeAutoHideTimer(id) {
			return setTimeout(() => this.hide(id), this.autohide)
		},

		/**
		 * Add an Alert message instance to the visible alerts.
		 *
		 * @param {Message} alert
		 * @return {Vue}
		 */
		push(alert) {
			let id = alert.getId()

			this.ids.push(id)
			this.messages[id] = alert

			if (this.autohide > 0) {
				alert.setTimer(this.makeAutoHideTimer(id))
			}

			return this
		},

		/**
		 * Remove an alert message from the collection.
		 *
		 * @param {String} id
		 * @return {Vue}
		 */
		remove(id) {
			let index = this.ids.findIndex(messageId => messageId === id)

			if (index >= 0) {
				this.ids.splice(index, 1)
				delete this.messages[id]
			}

			return this
		},

		/**
		 * Start the message deletion timer/timeout.
		 *
		 * @param {String} id
		 * @return {Vue}
		 */
		startRemoveTimer(id) {
			setTimeout(() => this.remove(id), 1000)

			return this
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'AlertContainer',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The amount of time in milli-seconds the alert message should be visible.
		 *
		 * @type {Object}
		 */
		autohide: {
			default: 5000,
			type: Number,
		},
	},

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.$app.use('App/Events/Dispatcher').remove('alert')
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @type {Object}
	 */
	created() {
		this.$app
			.use('App/Events/Dispatcher')
			.on('alert:hide', id => this.hide(id))
			.on('alert:show', alert => this.push(alert))
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			ids: [],
			messages: {},
		}
	},
}
</script>
