import {
	FETCHING,
	FETCHING_COMPLETE,
	LOADING,
	LOADING_COMPLETE,
	LOCKED,
	UNLOCK,
} from '@/store/mutation-types'

/**
 * The module's dispatchable actions.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default {
	/**
	 * Update the data fetching status and message.
	 *
	 * @param {Object} options.state
	 * @param {Function} options.commit
	 * @param {String} message
	 * @return {void}
	 */
	fetching({ state, commit }, message) {
		if (!state.fetching.status || state.fetching.message !== message) {
			commit(FETCHING, message)
		}
	},

	/**
	 * Update the data fetching status and message.
	 *
	 * @param {Object} options.state
	 * @param {Function} options.commit
	 * @return {void}
	 */
	fetchingComplete({ state, commit }) {
		if (state.fetching.status) {
			commit(FETCHING_COMPLETE)
		}
	},

	/**
	 * Update the page loading status and message.
	 *
	 * @param {Object} options.state
	 * @param {Function} options.commit
	 * @param {String} message
	 * @return {void}
	 */
	loading({ state, commit }, message) {
		if (!state.page.status || state.page.message !== message) {
			commit(LOADING, message)
		}
	},

	/**
	 * Mark the page loading status as complete.
	 *
	 * @param {Object} options.state
	 * @param {Function} options.commit
	 * @return {void}
	 */
	loadingComplete({ state, commit }) {
		if (state.page.status) {
			commit(LOADING_COMPLETE)
		}
	},

	/**
	 * Update the locked loading status and message.
	 *
	 * @param {Object} options.state
	 * @param {Function} options.commit
	 * @param {String} message
	 * @return {void}
	 */
	lock({ state, commit }, message) {
		if (!state.locked.status || state.locked.message !== message) {
			commit(LOCKED, message)
		}
	},

	/**
	 * Update all loaders as done/complete.
	 *
	 * @param {Function} options.dispatch
	 * @param {String} loader
	 * @return {void}
	 */
	ready({ dispatch }, loader = '') {
		switch (loader) {
			case 'locked':
				dispatch('unlock')
				break
			case 'page':
			case 'loading':
				dispatch('loadingComplete')
				break
			case 'all':
			default:
				dispatch('unlock')
				dispatch('loadingComplete')
				dispatch('fetchingComplete')
		}
	},

	/**
	 * Update the locked status as complete.
	 *
	 * @param {Object} options.state
	 * @param {Function} options.commit
	 * @return {void}
	 */
	unlock({ state, commit }) {
		if (state.locked.status) {
			commit(UNLOCK)
		}
	},
}
