/**
 * Global Vue.js directives.
 *
 * @see https://vuejs.org/v2/guide/custom-directive.html
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default {
	/**
	 * Toggle an element's focus when it is rendered.
	 *
	 * @type {Object}
	 */
	focus: {
		/**
		 * Toggle the element's focus when it is inserted/rendered.
		 *
		 * @param {HTMLElement} el
		 * @return {void}
		 */
		inserted(el) {
			el.focus()
		},
	},
}
