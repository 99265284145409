import Vue from 'vue'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import {
	FontAwesomeIcon,
	FontAwesomeLayers,
} from '@fortawesome/vue-fontawesome'

/**
 * Add the icons to the app's icon library.
 */
library.add(fal, far, fas, fad)

dom.watch()

/**
 * Register the global font awesome components.
 */
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
