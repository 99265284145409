import { api } from '@/store/helpers'
import actions from '@/store/helpers/actions'

/**
 * The module's dispatchable actions.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	...actions,

	/**
	 * Fetch all providers.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.rootGetters
	 * @return {Response}
	 */
	async get({ dispatch, rootGetters }) {
		const partner = rootGetters.partner.id

		try {
			const response = await api()
				.partners()
				.callTypes()
				.get(partner)

			const types = response.get('data', [])

			const requests = types.map(type => {
				return api()
					.calls()
					.starts(rootGetters['calls/startDateFilter'])
					.ends(rootGetters['calls/endDateFilter'])
					.types(type.id)
					.count(true, partner)
			})

			const callTypes = (await Promise.all(requests)).map(
				(response, index) => {
					const callType = types[index]

					const { total, urgent, non_urgent } = response.get('data')

					return {
						total,
						urgent,
						non_urgent,
						...callType,
					}
				}
			)

			dispatch('add', callTypes)

			return response
		} catch (error) {
			console.error(error)
		}
	},

	async getByType({ commit, rootGetters }, typeId) {
		const partner = rootGetters.partner.id
		try {
			const response = await api()
				.calls()
				.starts(rootGetters['calls/startDateFilter'])
				.ends(rootGetters['calls/endDateFilter'])
				.types(typeId)
				.count(true, partner)

			const { total, urgent, non_urgent } = response.get('data')

			const callType = {
				id: typeId,
				total,
				urgent,
				non_urgent,
			}
			commit('ADD_CALL_TYPE_COUNT', callType)

			return response
		} catch (error) {
			console.error(error)
		}
	},

	async updateCallTypeCount({ commit }, callType) {
		commit('ADD_CALL_TYPE_COUNT', callType)
	},
}
