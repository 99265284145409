import { api } from '@/store/helpers'
import actions from '@/store/helpers/actions'

/**
 * The module's dispatchable actions.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	...actions,

	/**
	 * Create a new time block.
	 *
	 * @param {Function} options.dispatch
	 *
	 * @param {Object} payload
	 * @return {Response}
	 */
	async create({ dispatch, rootGetters }, payload) {
		const response = await api()
			.partners()
			.timeBlocks()
			.create(rootGetters.partner.id, payload)

		await dispatch('add', response.get('data'))

		return response
	},

	/**
	 * Delete the given time block.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.rootGetters
	 * @param {Number} id
	 * @return {Response}
	 */
	async delete({ dispatch, rootGetters }, id) {
		const response = await api()
			.partners()
			.timeBlocks()
			.delete(rootGetters.partner.id, id)

		await dispatch('remove', id)

		return response
	},

	/**
	 * Find a time block by ud.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.rootGetters
	 * @param {Number} id
	 * @return {Response}
	 */
	async find({ dispatch, rootGetters }, id) {
		const response = await api()
			.partners()
			.timeBlocks()
			.find(rootGetters.partner.id, id)

		await dispatch('add', response.get('data'))

		return response
	},

	/**
	 * Fetch all the time blocks.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.rootGetters
	 * @return {Response}
	 */
	async get({ dispatch, rootGetters }) {
		const response = await api()
			.partners()
			.timeBlocks()
			.get(rootGetters.partner.id)

		await dispatch('add', response.get('data'))

		return response
	},

	/**
	 * Update the given time block.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.rootGetters
	 * @param {Object} payload
	 * @return {Response}
	 */
	async update({ dispatch, rootGetters }, payload) {
		const response = await api()
			.partners()
			.timeBlocks()
			.update(rootGetters.partner.id, payload)

		dispatch('add', response.get('data'))

		return response
	},
}
