/**
 * The module's reactive state getters.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default {
	/**
	 * Get the document's css body class list.
	 *
	 * @param {Object} state
	 * @return {Array}
	 */
	bodyClassList(state) {
		return state.bodyClasses
	},
}
