import Service from './Service'

/**
 * Time block API Service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class TimeBlocks extends Service {
	/**
	 * Create a new time block.
	 *
	 * @param {Number} partner
	 * @param {Object} timeBlock
	 * @return {Promise}
	 */
	create(partner, timeBlock) {
		return this.request.post(
			`v4/partners/${partner}/time-blocks`,
			timeBlock
		)
	}

	/**
	 * Delete the given time block.
	 *
	 * @param {Number} id
	 * @return {Promise}
	 */
	delete(partner, id) {
		return this.request.delete(`v4/partners/${partner}/time-blocks/${id}`)
	}

	/**
	 * Find a time block by id.
	 *
	 * @param {Number} partner
	 * @param {Number} id
	 * @return {Promise}
	 */
	find(partner, id) {
		return this.request.get(`v4/partners/${partner}/time-blocks/${id}`)
	}

	/**
	 * Get all the time blocks.
	 *
	 * @param {Number} partner
	 * @return {Promise}
	 */
	get(partner) {
		return this.request.get(`v4/partners/${partner}/time-blocks`)
	}

	/**
	 * Update a time block.
	 *
	 * @param {Number} partner
	 * @param {Object} timeBlock
	 * @return {Promise}
	 */
	update(partner, timeBlock) {
		return this.request.put(
			`v4/partners/${partner}/time-blocks/${timeBlock.id}`,
			timeBlock
		)
	}
}
