import Service from './Service'

/**
 * Users API service.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Users extends Service {
	/**
	 * Find a user by id.
	 *
	 * @param {Number} id
	 * @return {Promisee}
	 */
	find(id) {
		return this.request.get(`v4/users/${id}`)
	}
}
