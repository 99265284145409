/**
 * String based helper functions.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Str {
	/**
	 * Determine if the given item is a string.
	 *
	 * @param {*} str
	 * @return {Boolean}
	 */
	static isString(str) {
		return typeof str === 'string'
	}

	/**
	 * Determine if the given item can be cast to a string.
	 *
	 * @param {*} str
	 * @return {Boolean}
	 */
	static isStringable(str) {
		if (this.isString(str)) {
			return true
		}

		if (!str || typeof str === 'object' || typeof str === 'boolean') {
			return false
		}

		return typeof str.toString === 'function'
	}

	/**
	 * Cast the given item to a lowercase string.
	 *
	 * @param {*} str
	 * @return {String}
	 */
	static toLowerCase(str) {
		if (this.isString(str)) {
			return str.toLowerCase()
		}

		if (this.isStringable(str)) {
			return str.toString().toLowerCase()
		}
	}

	/**
	 * Generate a unique & RFC-4122 valid UUIDv4 string.
	 *
	 * @example
	 * 'xxxxxxxx-xxxx-4xxx-(8/9/a/b)xxx-xxxxxxxxxxxx'
	 *
	 * @return {String}
	 */
	static uuid() {
		const crypto = window.crypto || window.msCrypto

		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => {
			return (
				c ^
				(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
			).toString(16)
		})
	}
}
