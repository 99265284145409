import Notifier from './Notifier'
import ServiceProvider from 'App/Support/ServiceProvider'

/**
 * Alert service provider.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class AlertServiceProvider extends ServiceProvider {
	/**
	 * Register bindings into the container.
	 *
	 * @return {void}
	 */
	register() {
		this.app.singleton('App/Alerts/Notifier', ioc => {
			return new Notifier(ioc.use('App/Events/Dispatcher'))
		})
	}
}
