import Service from './Service'

/**
 * Call notes API service.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Notes extends Service {
	/**
	 * Create a new call note.
	 *
	 * @param {String} sid
	 * @param {String} content
	 * @return {Promise}
	 */
	create(sid, content) {
		return this.request.post(`v4/calls/${sid}/notes`, { content })
	}

	/**
	 * Delete a note by id.
	 *
	 * @param {String} sid
	 * @param {Object} note
	 * @return {Promise}
	 */
	delete(sid, note) {
		return this.request.delete(`v4/calls/${sid}/notes/${note.id}`)
	}

	/**
	 * Update a call note by id.
	 *
	 * @param {String} sid
	 * @param {Object} note
	 * @param {String} content
	 * @return {Promise}
	 */
	update(sid, note, content) {
		return this.request.put(`v4/calls/${sid}/notes/${note.id}`, {
			content,
		})
	}
}
