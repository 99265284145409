import Service from './Service'

/**
 * ProviderGroup API Service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class ProviderGroup extends Service {
	/**
	 * Get the provider group from an specific partner.
	 *
	 * @param {Number} id
	 * @return {Promise}
	 */
	get(id) {
		return this.request.get(
			`v3/partners/${id}/provider-group-providers`,
			this.getQueryParams()
		)
	}
}
