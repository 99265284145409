/**
 * Before route confirmation/change middleware.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Middleware {
	/**
	 * Create a new Middleware instance.
	 *
	 * @param {Container} container
	 */
	constructor(container) {
		/**
		 * The Application manager intance.
		 *
		 * @type {Application}
		 */
		this.app = container.use('App/Foundation/Application')
	}

	/**
	 * Handle the incoming route change.
	 *
	 * @param {Object} to
	 * @param {Object} from
	 * @param {Function} next
	 * @return {void}
	 */
	handle(to, from, next) {
		next()
	}
}
