import { bus } from './index'
import Dispatcher from './Dispatcher'
import ServiceProvider from 'App/Support/ServiceProvider'

/**
 * Event service provider.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class EventServiceProvider extends ServiceProvider {
	/**
	 * Register bindings into the container.
	 *
	 * @return {void}
	 */
	register() {
		this.app.singleton('App/Events/Dispatcher', () => {
			return new Dispatcher(bus)
		})
	}
}
