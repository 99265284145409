import Events from './Events'
import Service from './Service'
import Providers from './Providers'
import CoverageGaps from './CoverageGaps'

/**
 * Calendars API Service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Calendars extends Service {
	/**
	 * Make a new CoverageGaps resource instance.
	 *
	 * @return {CoverageGaps}
	 */
	gaps() {
		return new CoverageGaps(this.request)
	}

	/**
	 * Get all the partner's calendars.
	 *
	 * @param {Number} partner
	 * @return {Promise}
	 */
	get(partner) {
		return this.request.get(
			`v4/partners/${partner}/calendars`,
			this.getQueryParams()
		)
	}

	/**
	 * Make a new Events resource instance.
	 *
	 * @return {Events}
	 */
	events() {
		return new Events(this.request)
	}

	/**
	 * Make a new Providers resource instance.
	 *
	 * @return {Providers}
	 */
	providers() {
		return new Providers(this.request)
	}
}
