import Container from './Container'

/**
 * Export the application's Inversion-Of-Control (IOC) container.
 *
 * @type {Container}
 */
export const container = new Container()

/**
 * Bind the container's use method to the window as a helper.
 *
 * @type {Function}
 */
window.use = container.use.bind(container)
