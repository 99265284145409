import { mapState } from 'vuex'

/**
 * Interacts with body CSS classes mixin.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: mapState('ui', {
		bodyClassList: state => state.bodyClasses,
	}),

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Update the document's css body classes.
		 *
		 * @param {Array} classList
		 * @return {void}
		 */
		onBodyClassChange(classList) {
			if (document.body.classList.length > 0) {
				document.body.classList.remove(...document.body.classList)
			}

			if (classList.length > 0) {
				document.body.classList.add(...classList)
			}
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the body class list property for changes.
		 *
		 * @type {Object}
		 */
		bodyClassList: {
			handler: 'onBodyClassChange',
			immediate: true,
		},
	},
}
