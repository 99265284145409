import Service from './Service'

/**
 * Event types API Service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class EventTypes extends Service {
	/**
	 * Get all the event types.
	 *
	 * @return {Promise}
	 */
	get() {
		return this.request.get('v4/event-types')
	}
}
