/**
 * Base API service.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Service {
	/**
	 * Create a new Service instance.
	 *
	 * @param {Request} request
	 */
	constructor(request) {
		/**
		 * The HTTP Request instance used to make API requests.
		 *
		 * @type {Request}
		 */
		this.request = request

		/**
		 * The request options to send to the API.
		 *
		 * @type {Object}
		 */
		this.options = { params: {} }
	}

	/**
	 * Make a rejected promise containing the given error.
	 *
	 * @param {ErrorResponse} e
	 * @return {Promise}
	 */
	error(e) {
		return Promise.reject(e)
	}

	/**
	 * Get the request's query parameters.
	 *
	 * @return {Object}
	 */
	getQueryParams() {
		return this.options.params
	}

	/**
	 * Get the options to send with the request.
	 *
	 * @return {Object}
	 */
	getOptions() {
		return this.options
	}

	/**
	 * Add an item to a query param array key.
	 *
	 * @param {String} key
	 * @param {*} value
	 * @return {Service}
	 */
	pushQueryParam(key, value) {
		const param = this.options.params[key] || []

		this.options.params[key] = param.concat(value)

		return this
	}

	/**
	 * Remove a query param or a query param array's value.
	 *
	 * @param {String} key
	 * @param {*} value
	 * @return {Service}
	 */
	removeQueryParam(key, value = null) {
		if (value) {
			return this.removeFromQueryParamArray(key, value)
		}

		delete this.options.params[key]

		return this
	}

	/**
	 * Remove a value from a query param array.
	 *
	 * @param {String} param
	 * @param {*} value
	 * @return {Service}
	 */
	removeFromQueryParamArray(param, value) {
		const index = this.options.params[param].findIndex(
			item => item === value
		)

		if (index > -1) {
			this.options.params[param].splice(index, 1)
		}

		return this
	}

	/**
	 * Set a query parameter value.
	 *
	 * @param {String} key
	 * @param {*} value
	 * @return {Service}
	 */
	setQueryParam(key, value) {
		if (Array.isArray(this.options.params[key])) {
			return this.pushQueryParam(key, value)
		}

		this.options.params[key] = value

		return this
	}

	/**
	 * The container bindings to inject.
	 *
	 * @return {Array}
	 */
	static get inject() {
		return ['App/Http/Request']
	}
}
