/**
 * Base service provider.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class ServiceProvider {
	/**
	 * Create a new ServiceProvider instance.
	 *
	 * @param {Container} app
	 */
	constructor(app) {
		/**
		 * The application's IOC Container instance.
		 *
		 * @type {Container}
		 */
		this.app = app
	}
}
