import Service from './Service'

/**
 * Providers API service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Providers extends Service {
	/**
	 * Get all the providers by calendar.
	 *
	 * @param {Number} calendar
	 * @return {Promise}
	 */
	getByCalendar(calendar) {
		return this.request.get(
			`v4/calendars/${calendar}/providers`,
			this.getQueryParams()
		)
	}

	/**
	 * Update the given provider's information.
	 *
	 * @param {Number} id
	 * @param {Object} provider
	 * @return {Promise}
	 */
	update(id, provider) {
		return this.request.put(`v3/providers/${id}`, { provider })
	}
}
