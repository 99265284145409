import Middleware from './Middleware'

/**
 * Authentication check middleware.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class AuthCheck extends Middleware {
	/**
	 * Handle the incoming route change.
	 *
	 * @param {Object} to
	 * @param {Object} from
	 * @param {Function} next
	 * @return {void}
	 */
	handle(to, from, next) {
		if (this.shouldRedirectToLogin(to)) {
			return next({ name: 'login' })
		}

		next()
	}

	/**
	 * Determine if the route requires authentication.
	 *
	 * @param {Object} to
	 * @return {Booelan}
	 */
	isGuardedRoute(to) {
		return to.matched.some(route => route.meta.auth)
	}

	/**
	 * Determine if the current user is a guest (not authenticated).
	 *
	 * @return {Boolean}
	 */
	isGuest() {
		const auth = this.app.use('App/Auth/Auth')

		return auth.isGuest()
	}

	/**
	 * Determine if the user should be redirected to the login page.
	 *
	 * @param {Object} to
	 * @return {Boolean}
	 */
	shouldRedirectToLogin(to) {
		return this.isGuardedRoute(to) && this.isGuest()
	}
}
