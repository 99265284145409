/**
 * The main application manager.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Application {
	/**
	 * The singleton Application instance.
	 *
	 * @type {Application}
	 */
	static instance

	/**
	 * Create a new Application instance.
	 *
	 * @param {Container} container
	 * @param {Vuex.Store} store
	 * @param {VueRouter} router
	 */
	constructor(container, store, router) {
		/**
		 * The application's IOC Container instance.
		 *
		 * @type {Container}
		 */
		this.container = container

		/**
		 * The applicaiton's flux store implementation.
		 *
		 * @type {Vuex.Store}
		 */
		this.store = store

		/**
		 * The application's VueRouter instance.
		 *
		 * @type {VueRouter}
		 */
		this.router = router

		this._setInstance(this)
	}

	/**
	 * Resolve the API Client instance from the container.
	 *
	 * @return {Client}
	 */
	api() {
		return this.use('App/Api/Client')
	}

	/**
	 * Resolve the Auth Manager instance from the container.
	 *
	 * @return {Manager}
	 */
	auth() {
		return this.use('App/Auth/Auth')
	}

	/**
	 * Fire the given event(s) to all of the subscribed listeners.
	 *
	 * @param {String|String[]} events
	 * @param {?*} payload
	 * @return {Dispatcher}
	 */
	emit(...args) {
		return this.event().emit(...args)
	}

	/**
	 * Resolve the event Dispatcher instance from the container.
	 *
	 * @return {Dispatcher}
	 */
	event() {
		return this.use('App/Events/Dispatcher')
	}

	/**
	 * Resolve the Request instance from the container.
	 *
	 * @return {Request}
	 */
	http() {
		return this.use('App/Http/Request')
	}

	/**
	 * Remove all event listeners or a specific event listener callback.
	 *
	 * @param {String} event
	 * @param {Function} listener
	 * @return {Dispatcher}
	 */
	off(...args) {
		return this.event().off(...args)
	}

	/**
	 * Listen for the given event(s) to be fired.
	 *
	 * @param {String|String[]} events
	 * @param {Function} listener
	 * @return {Dispatcher}
	 */
	on(events, listener) {
		return this.event().on(events, listener)
	}

	/**
	 * Listen for the given event(s) to be fired once.
	 *
	 * @param {String|String[]} events
	 * @param {Function} listener
	 * @return {Dispatcher}
	 */
	once(events, listener) {
		return this.event().once(events, listener)
	}

	/**
	 * Resolve a namespace out of the IOC container.
	 *
	 * @param {String} namespace
	 * @param {...*} args
	 * @return {Object}
	 */
	use(namespace, ...args) {
		return this.container.use(namespace, ...args)
	}

	/**
	 * Set the singleton instance.
	 *
	 * @param {Application} instance
	 * @return {void}
	 */
	_setInstance(instance) {
		this.constructor.instance = instance
	}

	/**
	 * Get the current singleton instance.
	 *
	 * @return {Application}
	 */
	static getInstance() {
		return this.instance
	}
}
