import Service from './Service'

/**
 * Insights API service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Insights extends Service {
	/**
	 * Get the completed by count insights by partner id.
	 *
	 * @param {Number} id
	 * @return {Promise}
	 */
	completed(id) {
		return this.request.get(
			`v4/partners/${id}/insights/completed-by-volume`,
			this.getOptions()
		)
	}

	/**
	 * Get the daily call insights by partner id.
	 *
	 * @param {Number} id
	 * @return {Promise}
	 */
	daily(id) {
		return this.request.get(
			`v4/partners/${id}/insights/daily`,
			this.getOptions()
		)
	}

	/**
	 * Add end date to the query scope parameter.
	 *
	 * @param {String} date
	 * @return {Insights}
	 */
	end(date) {
		return this.setQueryParam('ends_at', date)
	}

	/**
	 * Get the fallback insights by partner id.
	 *
	 * @param {Number} id
	 * @return {Promise}
	 */
	fallback(id) {
		return this.request.get(
			`v4/partners/${id}/insights/provider-fallback-volume`,
			this.getOptions()
		)
	}

	/**
	 * Get the hourly call insights by partner id.
	 *
	 * @param {Number} id
	 * @return {Promise}
	 */
	hourly(id) {
		return this.request.get(
			`v4/partners/${id}/insights/hourly`,
			this.getOptions()
		)
	}

	/**
	 * Get the call count by provider insights by partner id.
	 *
	 * @param {Number} id
	 * @return {Promise}
	 */
	provider(id) {
		return this.request.get(
			`v4/partners/${id}/insights/provider-call-volume`,
			this.getOptions()
		)
	}

	/**
	 * Add start date to the query scope parameter.
	 *
	 * @param {String} date
	 * @return {Insights}
	 */
	start(date) {
		return this.setQueryParam('starts_at', date)
	}

	/**
	 * Get the time to respond insights by partner id.
	 *
	 * @param {Number} id
	 * @return {Promise}
	 */
	timeToRespond(id) {
		return this.request.get(
			`v4/partners/${id}/insights/time-to-respond`,
			this.getOptions()
		)
	}

	/**
	 * Get the call type call insights by partner id.
	 *
	 * @param {Number} id
	 * @return {Promise}
	 */
	type(id) {
		return this.request.get(
			`v4/partners/${id}/insights/call-type-volume`,
			this.getOptions()
		)
	}
}
