import Service from './Service'

/**
 * Event's exceptions API Service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class EventExceptions extends Service {
	/**
	 * Create a new event exception.
	 *
	 * @param {Number} event
	 * @param {String} starts_at
	 * @return {Promise}
	 */
	create(event, starts_at) {
		return this.request.post(`v4/events/${event}/exceptions`, {
			starts_at,
		})
	}

	/**
	 * Delete the exceptions for the given event id and exception id.
	 *
	 * @param {Number} event
	 * @param {Number} exception
	 * @return {Promise}
	 */
	delete(event, exception) {
		return this.request.delete(`v4/events/${event}/exceptions/${exception}`)
	}

	/**
	 * Get all event exceptions for the given event.
	 *
	 * @param {Number} event
	 * @return {Promise}
	 */
	get(event) {
		return this.request.get(`v4/events/${event}/exceptions`)
	}
}
