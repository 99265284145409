import actions from '@/store/helpers/actions'
import StreamChat from '@/app/Chat/StreamChat'
import {
	REMOVE_SEARCH_FILTER,
	SET_SEARCH_FILTER,
	SET_FILTER_IDS,
	UPDATE_META_OFFSET,
} from '@/store/mutation-types'

/**
 * The module's dispatchable actions.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	...actions,

	/**
	 * Fetch all messaging profiles by user id.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.rootGetters
	 * @param {Object} options.state
	 * @param {Function} options.commit
	 * @param {Number} page
	 * @return {Response}
	 */
	async get({ dispatch, rootGetters, state, commit }, page) {
		const hasSearch = state.filters.search.length > 0
		const activeProfile = rootGetters['messagingProfiles/activeProfile']

		let filters = {
			team: {},
			members: { $in: [activeProfile.stream_profile_id] },
		}

		let offset = page ? page : state.meta.offset

		if (hasSearch) {
			offset = 0

			filters = {
				...filters,
				'member.user.name': { $autocomplete: state.filters.search },
			}
		}

		const sort = [{ last_message_at: -1 }]

		const response = await StreamChat.queryChannels(filters, sort, offset)

		if (response.length > 0) {
			commit(UPDATE_META_OFFSET, state.meta.offset + response.length)
		}

		await dispatch('add', response)

		if (hasSearch) {
			commit(SET_FILTER_IDS, response)
		}

		return response
	},

	/**
	 * Remove the current search term.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @return {void}
	 */
	removeSearchFilter({ commit, state }) {
		if (state.filters.search.length > 0) {
			commit(REMOVE_SEARCH_FILTER)
		}
	},

	/**
	 * Set the filters to scope any future queries by.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @param {Array} payload.search
	 * @return {void}
	 */
	async setFilters({ commit, state }, { search }) {
		if (search && search !== state.filters.search) {
			commit(SET_SEARCH_FILTER, search)
		}
	},
}
