import plugin from './plugin'
import ui from './modules/ui'
import getters from './getters'
import auth from './modules/auth'
import calls from './modules/calls'
import pages from './modules/pages'
import events from './modules/events'
import loaders from './modules/loaders'
import partners from './modules/partners'
import channels from './modules/channels'
import callTypes from './modules/callTypes'
import calendars from './modules/calendars'
import providers from './modules/providers'
import eventTypes from './modules/eventTypes'
import timeBlocks from './modules/timeBlocks'
import messagingProfiles from './modules/messagingProfiles'

/**
 * Export the vuex store configuration.
 *
 * @type {Object}
 */
export default {
	getters,
	modules: {
		auth,
		calendars,
		calls,
		callTypes,
		channels,
		events,
		eventTypes,
		loaders,
		messagingProfiles,
		pages,
		partners,
		providers,
		timeBlocks,
		ui,
	},
	plugins: [plugin],
}
