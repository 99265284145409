import { any, count, find } from '@/store/helpers/getters'

/**
 * The module's reactive state getters.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default {
	any,
	count,
	find,

	/**
	 * Get the active partner.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	active(state) {
		return state.items[state.active] || null
	},

	/**
	 * Find a linked partner by id.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Object}
	 */
	findLinkedPartner(state, getters) {
		return linkedPartnerId => {
			let linkedPartner = {}
			const active = getters.active

			if (active && Array.isArray(active?.linked_partners)) {
				linkedPartner = active.linked_partners.find(
					linkedPartner => linkedPartner.id === linkedPartnerId
				)
			}

			return linkedPartner
		}
	},

	/**
	 * Find a provider by id.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Object}
	 */
	findProvider(state, getters) {
		return providerId => {
			const active = getters.active

			const provider = active?.providers.find(
				provider => provider.id === providerId
			)

			return provider
		}
	},

	/**
	 * Find a provider group by id.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Object}
	 */
	findProviderGroup(state, getters) {
		return providerGroupId => {
			const active = getters.active

			const providerGroup =
				active?.provider_groups?.find(
					providerGroup => providerGroup.id === providerGroupId
				) || false

			return providerGroup
		}
	},

	/**
	 * Get the active partner's calendars.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	getCalendars(state) {
		return id => {
			return state.items[id].calendars || null
		}
	},

	/**
	 * Get the active partner's linkedPartners.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	getLinkedPartners(state) {
		return id => {
			return state.items[id].linked_partners || null
		}
	},

	/**
	 * Get the active partner's providerGroups.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	getProviderGroups(state) {
		return id => {
			return state.items[id].provider_groups || null
		}
	},

	/**
	 * Determine if the active partner has cover my call enabled.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {Boolean}
	 */
	hasCoverMyCallEnabled(state, getters) {
		return !!(getters.active && getters.active.enable_cover_my_call)
	},

	/**
	 * Determine if the active partner has paging enabled.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {Boolean}
	 */
	hasPagingEnabled(state, getters) {
		return !!(getters.active && getters.active.enable_paging)
	},
}
