import Service from './Service'
import PersonalAccessTokens from './PersonalAccessTokens'

/**
 * Authentication API service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Auth extends Service {
	/**
	 * Attempt to login a user via email and password.
	 *
	 * @param {String} email
	 * @param {String} password
	 * @return {Promise}
	 */
	login(email, password) {
		return this.request.post('v4/auth/login', {
			email,
			password,
		})
	}

	/**
	 * Make a new PersonalAccessTokens resource instance.
	 *
	 * @return {PersonalAccessTokens}
	 */
	personalAccessTokens() {
		return new PersonalAccessTokens(this.request)
	}
}
