import {
	LOCK_SCROLLING,
	UNLOCK_SCROLLING,
	UPDATE_ARIA_MESSAGE,
} from '@/store/mutation-types'

/**
 * The module's dispatchable actions
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default {
	/**
	 * Add the scoll-lock css class to the body class list.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @return {void}
	 */
	lockPageScroll({ commit, state }) {
		if (!state.bodyClasses.includes('scroll-lock')) {
			commit(LOCK_SCROLLING)
		}
	},

	/**
	 * Add the scoll-lock css class to the body class list.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @return {void}
	 */
	unlockPageScroll({ commit, state }) {
		if (state.bodyClasses.includes('scroll-lock')) {
			commit(UNLOCK_SCROLLING)
		}
	},

	/**
	 * Update the screen reader ARIA message.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @param {String} message
	 * @return {void}
	 */
	updateAriaMessage({ commit, state }, message) {
		if (state.ariaMessage !== message) {
			commit(UPDATE_ARIA_MESSAGE, message)
		}
	},
}
