/*
|--------------------------------------------------------------------------
| Vue.js Key Code Shortcuts
|--------------------------------------------------------------------------
|
| The following key shortcuts and corresponding key codes are registered
| globally with Vue. Each key shortcut can be referenced by name as an event
| modifier. The following list must use "kebab-case." See more about the Vue
| key code configuration here: https://vuejs.org/v2/api/#keyCodes
|
*/

export default {}
