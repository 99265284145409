import Service from './Service'

/**
 * PartnerRequests API service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class PartnerRequests extends Service {
	/**
	 * Create a new Service instance.
	 *
	 * @param {Request} request
	 * @param {Number?} partner
	 */
	constructor(request, partner) {
		super(request)

		/**
		 * The active partner id to scope the calls requests by.
		 *
		 * @type {Number?}
		 */
		this.partner = partner
	}

	/**
	 * Get all requests by partner from the API.
	 *
	 * @param {Number?} partner
	 * @return {Promise}
	 */
	get(partner = null) {
		partner = partner || this.partner

		return this.request.get(`v4/partners/${partner}/requests`)
	}
}
