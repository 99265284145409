import ErrorMessage from './Messages/ErrorMessage'
import SuccessMessage from './Messages/SuccessMessage'
import WarningMessage from './Messages/WarningMessage'

/**
 * Alert message factory.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Factory {
	/**
	 * The error alert message type.
	 *
	 * @type {String}
	 */
	static ERROR = 'error'

	/**
	 * The danger alert message type.
	 *
	 * @type {String}
	 */
	static DANGER = 'danger'

	/**
	 * The success alert message type.
	 *
	 * @type {String}
	 */
	static SUCCESS = 'success'

	/**
	 * The warning alert message type.
	 *
	 * @type {String}
	 */
	static WARNING = 'warning'

	/**
	 * Make a new alert message.
	 *
	 * @param {String} type
	 * @param {String} message
	 * @return {Message}
	 */
	static make(type, message) {
		let alert

		switch (type) {
			case this.ERROR:
			case this.DANGER:
				alert = new ErrorMessage(message)
				break
			case this.SUCCESS:
				alert = new SuccessMessage(message)
				break
			case this.WARNING:
				alert = new WarningMessage(message)
				break
			default:
				throw new Error(
					`The alert message type [${type}] is not valid.`
				)
		}

		return alert
	}
}
