import Service from './Service'

/**
 * Password API service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Password extends Service {
	/**
	 * Request a forgot password email or text message be sent.
	 *
	 * @param {String} email
	 * @return {Promise}
	 */
	forgot(email) {
		return this.request.post('v3/password/reset', { email })
	}

	/**
	 * Reset a user's password via a password reset token.
	 *
	 * @param {String} token
	 * @param {String} email
	 * @param {String} password
	 * @param {String} password_confirmation
	 * @return {Promise}
	 */
	update(token, email, password, password_confirmation) {
		return this.request.http.post(`v3/password/reset/${token}`, {
			email,
			password,
			password_confirmation,
		})
	}
}
