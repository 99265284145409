/**
 * Determine if the given item is an array.
 *
 * @param {*} item
 * @return {Boolean}
 */
export const isArray = item => Array.isArray(item)

/**
 * Determine if the given item is an object.
 *
 * @param {*} item
 * @return {Boolean}
 */
export const isObject = item => typeof item === 'object' && item !== null

/**
 * Save the given key/value pair to local storage.
 *
 * @param {String} key
 * @param {Object} data
 * @return {Object}
 */
export const saveToStorage = (key, data) => {
	let value = data

	if (data && typeof data === 'object') {
		const cached_at = new Date().toISOString()

		value = { cached_at, ...data }
	}

	localStorage.setItem(key, JSON.stringify(value))

	return data
}

/**
 * Cast the given item to an array.
 *
 * @param {*} item
 * @return {Array}
 */
export const toArray = item => {
	if (isArray(item)) {
		return item
	}

	if (item) {
		return [].concat(item)
	}

	return []
}
