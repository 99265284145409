import Image from './Image'
import TextWrapper from './TextWrapper'

/**
 * Columns definition object.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Columns {
	/**
	 * Make a new Columns instance.
	 */
	constructor() {
		this.columns = []
	}

	/**
	 * Add a new image to the columns array.
	 *
	 * @param {String} base64
	 * @return {Columns}
	 */
	image(base64) {
		const image = new Image(base64)

		this.columns.push(image)

		return image
	}

	/**
	 * Add a new text wrapper to the columns array.
	 *
	 * @return {Columns}
	 */
	textWrapper() {
		const wrapper = new TextWrapper()

		this.columns.push(wrapper)

		return wrapper
	}

	/**
	 * Get the instance as a definition object.
	 *
	 * @return {Object}
	 */
	toObject() {
		return {
			columns: this.columns.map(column => column.toObject()),
		}
	}
}
