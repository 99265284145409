/**
 * API service manager.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Manager {
	/**
	 * Create a new Manager instance.
	 *
	 * @param {Container} container
	 * @param {Object} services
	 */
	constructor(container, services = {}) {
		this.services = services || {}
		this.container = container
	}

	/**
	 * Add a service driver to the manager.
	 *
	 * @param {String} key
	 * @param {Function} implementation
	 * @return {void}
	 */
	extend(key, implementation) {
		this.services[key] = implementation
	}

	/**
	 * Make a new service instance registered with the given name.
	 *
	 * @param {String} name
	 * @param {Array?} params
	 * @return {Service}
	 */
	service(name, params) {
		Array.isArray(params) || (params = [])

		return this.container.make(this.services[name], ...params)
	}
}
