import { sortBy } from 'lodash'
import { any, find } from '@/store/helpers/getters'

/**
 * The module's reactive state getters.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	any,
	find,

	/**
	 * Get all the providers.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Array}
	 */
	all(state, getters) {
		if (!getters.any) {
			return []
		}

		return state.ids.map(id => state.items[id])
	},

	/**
	 * Get the external providers.
	 *
	 * @param {Object} state
	 * @return {Array}
	 */
	external(state) {
		return sortBy(
			state.external.map(external => state.items[external]),
			'display_name'
		)
	},

	/**
	 * Get all the providers by partner id.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Array}
	 */
	getByPartner(state, getters) {
		return id => {
			return getters.all.filter(provider => provider.partner_id === id)
		}
	},

	/**
	 * Get external the providers by partner id.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Array}
	 */
	getExternalByPartner(state, getters) {
		return id => {
			return getters.external.filter(provider => {
				return provider.partner_id === id
			})
		}
	},

	/**
	 * Get internal the providers by partner id.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Array}
	 */
	getInternalByPartner(state, getters) {
		return id => {
			return getters.internal.filter(provider => {
				return provider.partner_id === id
			})
		}
	},

	/**
	 * Get the internal providers.
	 *
	 * @param {Object} state
	 * @return {Array}
	 */
	internal(state) {
		return sortBy(
			state.internal.map(internal => state.items[internal]),
			'display_name'
		)
	},
}
