/**
 * Style definition object.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Style {
	/**
	 * Make a nre Style instance.
	 *
	 * @param {String} name
	 */
	constructor(name) {
		/**
		 * The name of the predefined style.
		 *
		 * @type {String}
		 */
		this.name = name

		/**
		 * The style definition/settings.
		 *
		 * @type {Object}
		 */
		this.definition = {}
	}

	/**
	 * Set the content's alignment position.
	 *
	 * @param {String} position
	 * @return {Style}
	 */
	align(position) {
		if (!['left', 'center', 'right'].includes(position)) {
			throw new Error(
				`PDF content position must be [left, center, right]. [${position}] was given.`
			)
		}

		return this.set('alignment', position)
	}

	/**
	 * Set the content's background color.
	 *
	 * @param {String} background
	 * @return {Style}
	 */
	background(background) {
		return this.set('background', background)
	}

	/**
	 * Set the content's text as bold.
	 *
	 * @return {Style}
	 */
	bold() {
		return this.set('bold', true)
	}

	/**
	 * Center-align the content's text.
	 *
	 * @return {Style}
	 */
	center() {
		return this.align('center')
	}

	/**
	 * Set the color of the content's text.
	 *
	 * @param {String} color
	 * @return {Style}
	 */
	color(color) {
		return this.set('color', color)
	}

	/**
	 * Set the content's text font.
	 *
	 * @param {String} font
	 * @return {Style}
	 */
	font(font) {
		return this.set('font', font)
	}

	/**
	 * Set the content's font text size.
	 *
	 * @param {Number} fontSize
	 * @return {Style}
	 */
	fontSize(fontSize) {
		return this.set('fontSize', fontSize)
	}

	/**
	 * Get name of the predefined style.
	 *
	 * @return {String}
	 */
	getName() {
		return this.name
	}

	/**
	 * Set the content's text as italic.
	 *
	 * @return {Style}
	 */
	italics() {
		return this.set('italics', true)
	}

	/**
	 * Left-align the content's text.
	 *
	 * @return {Style}
	 */
	left() {
		return this.align('left')
	}

	/**
	 * Right-align the content's text.
	 *
	 * @return {Style}
	 */
	right() {
		return this.align('right')
	}

	/**
	 * Set the content's margins [left, top, right, bottom], [H, V], [all].
	 *
	 * @param {Array} margin
	 * @return {Style}
	 */
	margin(margin) {
		return this.set('margin', margin)
	}

	/**
	 * Add the style to the definition object.
	 *
	 * @param {String} key
	 * @param {String} value
	 * @return {Style}
	 */
	set(key, value) {
		this.definition[key] = value

		return this
	}

	/**
	 * Get the instance as a definition object.
	 *
	 * @return {Object}
	 */
	toObject() {
		return this.definition
	}
}
