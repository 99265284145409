import Service from './Service'

/**
 * PersonalAccessToken API service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class PersonalAccessToken extends Service {
	/**
	 * Attempt to delete a personal access token.
	 *
	 * @param {String} token
	 * @return {Promise}
	 */
	delete(token) {
		return this.request.delete(`v3/oauth/personal-access-tokens/${token}`)
	}
}
