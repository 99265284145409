import Vue from 'vue'
import Task from './Task'
import keyCodes from '@/config/keys'
import filters from '@/config/filters'
import directives from '@/config/directives'

/**
 * Add the global filters, directives, mixins, etc. to the Vue.js instance.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class ConfigureVue extends Task {
	/**
	 * Run the asynchronous task and resolve when complete.
	 *
	 * @param {Function} next
	 * @return {void}
	 */
	run(next) {
		this._addFilters()
		this._addKeyCodes()
		this._addDirectives()
		next()
	}

	/**
	 * Register a new directive with Vue.
	 *
	 * @param {String} name
	 * @param {Object|Function} directive
	 * @return {void}
	 */
	_addDirective(name, directive) {
		Vue.directive(name, directive)
	}

	/**
	 * Bind the global directives to Vue.
	 *
	 * @return {void}
	 */
	_addDirectives() {
		for (let name in directives) {
			this._addDirective(name, directives[name])
		}
	}

	/**
	 * Add a component filter function to Vue.
	 *
	 * @param {String} name
	 * @param {Function} filter
	 * @return {void}
	 */
	_addFilter(name, filter) {
		Vue.filter(name, filter)
	}

	/**
	 * Bind the global filters to the Vue instance.
	 *
	 * @return {void}
	 */
	_addFilters() {
		for (let name in filters) {
			this._addFilter(name, filters[name])
		}
	}

	/**
	 * Bind the global shortcut keys to the Vue instance.
	 *
	 * @return {void}
	 */
	_addKeyCodes() {
		Vue.config.keyCodes = keyCodes
	}
}
