import { any, find } from '@/store/helpers/getters'

/**
 * The module's reactive state getters.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	any,
	find,
	get(state) {
		const ids = state.ids
		return ids.map(id => state.items[id])
	},
}
