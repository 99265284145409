import {
	ADD_ITEMS,
	ADD_ITEMS_TO_FRONT,
	REMOVE_BY_ID,
	RESET,
	SET_ITEMS,
	SET_META,
	UPDATE_ITEM,
} from '@/store/mutation-types'
import { isObject, toArray } from 'App/Helpers'

/**
 * Add an entity/entities to the module.
 *
 * @param {Function} options.commit
 * @param {Array|Object} payload
 * @return {void}
 */
export const add = ({ commit }, payload) => {
	const models = toArray(payload)

	if (models.length > 0) {
		commit(ADD_ITEMS, models)
	}
}

/**
 * Add an entity/entities to the beginning of module.
 *
 * @param {Function} options.commit
 * @param {Array|Object} payload
 * @return {void}
 */
export const addToFront = ({ commit }, payload) => {
	const models = toArray(payload)

	if (models.length > 0) {
		commit(ADD_ITEMS_TO_FRONT, models)
	}
}

/**
 * Remove an entity by id.
 *
 * @param {Function} options.commit
 * @param {Object} options.state
 * @param {Number} id
 * @return {void}
 */
export const remove = ({ commit, state }, id) => {
	if (state.ids.includes(id)) {
		commit(REMOVE_BY_ID, id)
	}
}

/**
 * Replace/update a model's state in the store.
 *
 * @param {Function} options.commit
 * @param {Object} options.state
 * @param {Object} model
 * @return {void}
 */
export const replace = ({ commit, state }, model) => {
	if (isObject(model) && state.ids.includes(model.id)) {
		commit(UPDATE_ITEM, model)
	}
}

/**
 * Reset the module back to it's original state.
 *
 * @param {Function} options.commit
 * @param {Object} options.state
 * @return {void}
 */
export const reset = ({ commit, state }) => {
	if (state.ids.length > 0) {
		commit(RESET)
	}
}

/**
 * Set the module's entities.
 *
 * @param {Function} options.commit
 * @param {Array|Object} payload
 * @return {void}
 */
export const set = ({ commit }, payload) => {
	const models = toArray(payload)

	if (models.length > 0) {
		commit(SET_ITEMS, models)
	}
}

/**
 * Set the module's meta data.
 *
 * @param {Function} options.commit
 * @param {Array|Object} payload
 * @return {void}
 */
export const setMeta = ({ commit, state }, payload) => {
	if (isObject(payload) && state !== payload) {
		commit(SET_META, payload)
	}
}

export default {
	add,
	addToFront,
	remove,
	replace,
	reset,
	set,
	setMeta,
}
