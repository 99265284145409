<template>
	<div id="app" class="app-container">
		<loader-bar />
		<alert-container />
		<router-view class="app-layout" />
	</div>
</template>
<script>
import LoaderBar from './components/LoaderBar'
import AlertContainer from '@/components/Alert/Container.vue'
import InteractsWithBodyClass from './mixins/InteractsWithBodyClass'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		LoaderBar,
		AlertContainer,
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Detect if the user is using IE or Edge.
		 *
		 * @return {Boolean}
		 */
		isInternetExplorerOrEdge() {
			const userAgent = navigator.userAgent

			if (userAgent.indexOf('MSIE ') > -1) {
				return true
			}

			if (userAgent.indexOf('Trident/') > -1) {
				return true
			}

			if (userAgent.indexOf('Edge/') > -1) {
				return true
			}

			return false
		},
	},

	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [InteractsWithBodyClass],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'App',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the route for changes.
		 *
		 * @return {void}
		 */
		$route() {
			window.Intercom && window.Intercom('update')
			if (this.isIE) {
				this.$alert.error('browser.error')
			}
		},
	},

	/**
	 * The component's mounted lifecycle hook.
	 *
	 * @return {void}
	 */
	mounted() {
		if (window.Intercom) {
			window.Intercom('shutdown')
			window.Intercom('boot', {
				api_base:
					process.env.VUE_APP_INTERCOM_API ||
					'https://api-iam.intercom.io',
				app_id: process.env.VUE_APP_INTERCOM_ID || 'z1oijek0',
			})
		}
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	created() {
		if (this.isInternetExplorerOrEdge()) {
			this.isIE = true
		}
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			isIE: false,
		}
	},
}
</script>
<style lang="scss">
@import './sass/app.scss';
</style>
