/**
 * The module's reactive state getters.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default {
	/**
	 * Get the first active loader.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {Object}
	 */
	active(state, getters) {
		return getters.all.find(loader => loader.status)
	},

	/**
	 * Get all loaders as an array in order of priority.
	 *
	 * @param {Object} state
	 * @return {Array}
	 */
	all(state) {
		return [state.fetching, state.locked, state.page]
	},

	/**
	 * Determine if there is an active loader.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {Boolean}
	 */
	loading(state, getters) {
		return !!getters.active
	},

	/**
	 * Get the active loader's message.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {String}
	 */
	message(state, getters) {
		if (getters.loading) {
			return getters.active.message
		}

		return ''
	},
}
