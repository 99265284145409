import Task from './Task'
import router from '@/router'
import middleware from '@/config/middleware'

/**
 * Add the middleware/guards to the router.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class AddRouterMiddleware extends Task {
	/**
	 * Run the asynchronous task and resolve when complete.
	 *
	 * @param {Function} next
	 * @return {void}
	 */
	async run(next) {
		this._addBeforeMiddlewares()
		this._addAfterMiddlewares()

		next()
	}

	/**
	 * Add middleware to run after a route is confirmed.
	 *
	 * @param {AfterMiddleware} Guard
	 * @return {void}
	 */
	_addAfterMiddleware(Middleware) {
		router.afterEach((to, from) => Middleware.handle(to, from))
	}

	/**
	 * Add the after middleware to the router.
	 *
	 * @return {void}
	 */
	_addAfterMiddlewares() {
		middleware.after.forEach(Middleware => {
			this._addAfterMiddleware(new Middleware(this.container))
		})
	}

	/**
	 * Add a middleware to run before a route is confirmed.
	 *
	 * @param {Middleware} Middleware
	 * @return {void}
	 */
	_addBeforeMiddleware(Middleware) {
		router.beforeEach((to, from, next) => Middleware.handle(to, from, next))
	}

	/**
	 * Add the before guards/middleware to the router.
	 *
	 * @return {void}
	 */
	_addBeforeMiddlewares() {
		middleware.before.forEach(Middleware => {
			this._addBeforeMiddleware(new Middleware(this.container))
		})
	}
}
