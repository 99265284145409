import { api } from '@/store/helpers'
import actions from '@/store/helpers/actions'

/**
 * The module's dispatchable actions.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	...actions,

	/**
	 * Fetch all messaging profiles by user id.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.rootGetters
	 * @return {Response}
	 */
	async get({ dispatch }, userId) {
		const response = await api()
			.messagingProfiles()
			.get(userId)

		dispatch('add', response.get('data', []))

		return response
	},
}
