/**
 * The module's default state.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default function state() {
	return {
		ariaMessage: '',
		bodyClasses: [],
	}
}
