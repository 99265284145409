import axios from 'axios'
import Request from './Request'
import ServiceProvider from 'App/Support/ServiceProvider'

/**
 * The default API url/path to use when not specified in the environment.
 *
 * @type {String}
 */
const DEFAULT_API_URL = 'api'

/**
 * The API url environmnent key name.
 *
 * @type {String}
 */
const VUE_APP_API_URL = 'VUE_APP_API_URL'

/**
 * The API url path environmnent key name.
 *
 * @type {String}
 */
const VUE_APP_API_PATH = 'VUE_APP_API_PATH'

/**
 * Http service provider.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class HttpServiceProvider extends ServiceProvider {
	/**
	 * Register bindings into the container.
	 *
	 * @return {void}
	 */
	register() {
		this.app.singleton('axios', () => {
			return axios.create({
				baseURL: this._getBaseUrl(),
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
				},
			})
		})

		this.app.singleton('App/Http/Request', ioc => {
			return new Request(ioc.use('axios'))
		})
	}

	/**
	 * Get the environment's base API url or path.
	 *
	 * @return {String}
	 */
	_getBaseUrl() {
		const url = process.env[VUE_APP_API_URL]
		const path = process.env[VUE_APP_API_PATH]

		if (!url) {
			return DEFAULT_API_URL
		}

		return `${url}/${path}`
	}
}
