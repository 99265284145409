import stateFactory from './state'
import * as helpers from '@/store/helpers'
import {
	ADD_ITEMS,
	REMOVE_BY_ID,
	REMOVE_SEARCH_FILTER,
	RESET,
	SET_SEARCH_FILTER,
	SET_FILTER_IDS,
	UPDATE_META_OFFSET,
	UPDATE_META_LAST_PAGE,
} from '@/store/mutation-types'

/**
 * The module's state mutators.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	/**
	 * Add the given model(s) to the store.
	 *
	 * @param {Object} state
	 * @param {Array} models
	 */
	[ADD_ITEMS](state, models) {
		helpers.add(state, models)
	},

	/**
	 * Remove a model from the module by id.
	 *
	 * @param {Object} state
	 * @param {Number} id
	 * @return {void}
	 */
	[REMOVE_BY_ID](state, id) {
		helpers.removeById(state, id)
	},

	/**
	 * Remove the current search term.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[REMOVE_SEARCH_FILTER](state) {
		state.filters.search = ''
		state.filters.ids = []
	},

	/**
	 * Reset the given state back to its original value.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[RESET](state) {
		helpers.reset(state, stateFactory())
	},

	/**
	 * Set the filter ids from the given model.
	 *
	 * @param {Object} state
	 * @param {Array} models
	 * @return {void}
	 */
	[SET_FILTER_IDS](state, models) {
		if (models.length === 0) {
			return
		}

		models.forEach(model => {
			if (!state.filters.ids.includes(model.id)) {
				state.filters.ids.push(model.id)
			}
		})
	},

	/**
	 * Set the channel's search term.
	 *
	 * @param {Object} state
	 * @param {String} term
	 * @return {void}
	 */
	[SET_SEARCH_FILTER](state, term) {
		state.filters.search = term
	},

	/**
	 * Update the meta's offset value.
	 *
	 * @param {Object} state
	 * @param {String} term
	 * @return {void}
	 */
	[UPDATE_META_LAST_PAGE](state, lastPage) {
		state.meta.last_page = lastPage
	},

	/**
	 * Update the meta's offset value.
	 *
	 * @param {Object} state
	 * @param {String} offset
	 * @return {void}
	 */
	[UPDATE_META_OFFSET](state, offset) {
		state.meta.offset = offset
	},
}
