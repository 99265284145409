import Service from './Service'

/**
 * Schedules API Service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Schedules extends Service {
	/**
	 * Create a new Service instance.
	 *
	 * @param {Request} request
	 * @param {Number?} partner
	 */
	constructor(request, partner) {
		super(request)

		/**
		 * The active partner id to scope API the requests.
		 *
		 * @type {Number?}
		 */
		this.partner = partner
	}

	/**
	 * Get all schedules by partner from the API.
	 *
	 * @param {Number?} partner
	 * @return {Promise}
	 */
	get(partner = null) {
		partner = partner || this.partner

		return this.request.get(`v4/partners/${partner}/schedules`)
	}

	/**
	 * Create the given partner's daily schedule.
	 *
	 * @param {Array} schedules
	 * @param {Number} partner
	 * @return {Promise}
	 */
	create(schedules, partner = null) {
		partner = partner || this.partner
		schedules = Array.isArray(schedules) ? schedules : []

		schedules = schedules.map(({ day_name, ends_at, starts_at }) => {
			return { day_name, starts_at, ends_at }
		})

		return this.request.post(`v4/partners/${partner}/schedules`, {
			schedules,
		})
	}
}
