import Task from './Task'
import app from '@/config/app'

/**
 * Add the container aliases and register the service providers.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class RegisterServiceProviders extends Task {
	/**
	 * Run the asynchronous task and resolve when complete.
	 *
	 * @param {Function} next
	 * @return {void}
	 */
	async run(next) {
		this.registrar.setAliases(app.aliases)
		this.registrar.setProviders(app.providers)
		this.registrar.register()
		this._registerIntercom()

		next()
	}

	/**
	 * Initialize Intercom with Widget injection.
	 *
	 * @return {void}
	 */
	_registerIntercom() {
		;(function() {
			var w = window
			var ic = w.Intercom
			if (typeof ic === 'function') {
				ic('reattach_activator')
				ic('update', w.intercomSettings)
			} else {
				var d = document
				var i = function() {
					i.c(arguments)
				}

				i.q = []
				i.c = function(args) {
					i.q.push(args)
				}

				w.Intercom = i

				var l = function() {
					var s = d.createElement('script')
					s.type = 'text/javascript'
					s.async = true
					s.src = `https://widget.intercom.io/widget/${process.env
						.VUE_APP_INTERCOM_ID || 'z1oijek0'}`
					var x = d.getElementsByTagName('script')[0]
					x.parentNode.insertBefore(s, x)
				}

				if (document.readyState === 'complete') {
					l()
				} else if (w.attachEvent) {
					w.attachEvent('onload', l)
				} else {
					w.addEventListener('load', l, false)
				}
			}
		})()
	}
}
