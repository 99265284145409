import Task from './Task'
import { StreamChat } from 'stream-chat'
import Application from 'App/Foundation/Application'

/**
 * Load and initialize Stream Chat.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class InitializeStreamChat extends Task {
	/**
	 * Run the asynchronous task and resolve when complete.
	 *
	 * @param {Function} next
	 * @return {void}
	 */
	async run(next) {
		if (this.shouldLoadStreamChat()) {
			const app = this.container.use('App/Foundation/Application')

			app.container.singleton('Stream', () => {
				console.log('Establishing Stream Chat connection.')

				const client = StreamChat.getInstance(
					process.env.VUE_APP_STREAM_CHAT_KEY
				)

				return client
			})

			app.on('auth:success', this.onAuthSuccess)

			app.on('partner:change', this.onPartnerChange)
		}

		next()
	}

	/**
	 * Get the Stream Chat API key from the environment.
	 *
	 * @return {String}
	 */
	getStreamChatApiKey() {
		return process.env.VUE_APP_STREAM_CHAT_KEY
	}

	/**
	 * Determine if the application should load Stream Chat.
	 *
	 * @return {Boolean}
	 */
	shouldLoadStreamChat() {
		return !!this.getStreamChatApiKey()
	}

	/**
	 * Initialize Stream Chat when the user logs in.
	 *
	 * @param {Object} app
	 * @return {void}
	 */
	async onAuthSuccess(app) {
		const user = app.store.state.auth.user

		if (!user) {
			return
		}

		const messagingProfile =
			app.store.getters['messagingProfiles/activeProfile']

		if (!messagingProfile) {
			return
		}

		const streamUser = {
			id: messagingProfile.stream_profile_id,
			name: `${user.first_name} ${user.last_name}`,
			image: user.profile_image,
		}

		console.log(
			`Connecting to Stream Chat: partner_${messagingProfile.partner_id}`
		)

		await app.use('Stream').connectUser(streamUser, messagingProfile.token)
	}

	/**
	 * Handle the active partner being changed.
	 *
	 * @param {Number} options.leave
	 * @return {void}
	 */
	async onPartnerChange({ leave, subscribe }) {
		const app = Application.getInstance()
		const stream = app.use('Stream')
		const isActiveProfile =
			app.store.getters['messagingProfiles/isActiveProfile']

		if (leave && stream.user && isActiveProfile) {
			console.log(`Leaving Stream Chat connection: partner_${leave}`)

			await stream.disconnectUser()
		}

		const messagingProfiles = app.store.getters['messagingProfiles/all']

		const messagingProfile = messagingProfiles.find(
			profile => profile.partner_id === parseInt(subscribe)
		)

		if (!messagingProfile) {
			return
		}

		if (subscribe) {
			console.log(`Connecting to Stream Chat: partner_${subscribe}`)

			const user = app.store.state.auth.user

			const streamUser = {
				id: messagingProfile.stream_profile_id,
				name: `${user.first_name} ${user.last_name}`,
				image: user.profile_image,
			}

			await stream.connectUser(streamUser, messagingProfile.token)
		}
	}
}
