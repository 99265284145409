/**
 * Base start-up engine task.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Task {
	/**
	 * Create a new Task instance.
	 *
	 * @param {Registrar} registrar
	 */
	constructor(registrar) {
		/**
		 * The application's service provider registrar instance.
		 *
		 * @type {Registrar}
		 */
		this.registrar = registrar

		/**
		 * The application's IOC container instance.
		 *
		 * @type {Container}
		 */
		this.container = registrar.container
	}

	/**
	 * Determine if the app is running in a production environment.
	 *
	 * @return {Boolean}
	 */
	isProduction() {
		return process.env.NODE_ENV === 'production'
	}

	/**
	 * Run the asynchronous task and resolve when complete.
	 *
	 * @param {Function} next
	 * @return {void}
	 */
	async run() {
		throw new Error(
			`The App/Start/Tasks/Task::run method must be implemented.`
		)
	}
}
