import Service from './Service'

/**
 * CoverageGaps API Service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class CoverageGaps extends Service {
	/**
	 * Set the end date query scope parameter.
	 *
	 * @param {String} date
	 * @return {Events}
	 */
	end(date) {
		return this.setQueryParam('ends_at', date)
	}

	/**
	 * Get all the coverage gaps by calendar id from the API.
	 *
	 * @param {Number} calendar
	 * @return {Promise}
	 */
	get(calendar) {
		return this.request.get(
			`v4/calendars/${calendar}/coverage-gaps`,
			this.getOptions()
		)
	}

	/**
	 * Set the start date query scope parameter.
	 *
	 * @param {String} date
	 * @return {Events}
	 */
	start(date) {
		return this.setQueryParam('starts_at', date)
	}
}
