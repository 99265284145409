import Text from './Text'
import Style from './Style'

/**
 * Text wrapper definition object.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class TextWrapper {
	/**
	 * Make a new TextWrapper instance.
	 *
	 * @param {?Style|string} style
	 */
	constructor(style = null) {
		this.items = []

		this.style = style
	}

	/**
	 * Add style to TextWrapper.
	 *
	 * @return {Style}
	 */
	addStyles() {
		this.style = new Style('text-wrapper-custom')

		return this.style
	}

	/**
	 * Get the custom styles definition or style name object.
	 *
	 * @return {Object}
	 */
	getStyleObject() {
		if (!this.style) {
			return
		}

		if (typeof this.style === 'string') {
			return { style: this.style }
		}

		return this.style.toObject()
	}

	/**
	 * Create a new child text block.
	 *
	 * @param {String} item
	 * @param {String} style
	 * @return {Columns}
	 */
	text(item, style = null) {
		const text = new Text(item, style)

		this.items.push(text)

		return text
	}

	/**
	 * Get the instance as a definition object.
	 *
	 * @return {Object}
	 */
	toObject() {
		const style = this.getStyleObject()

		const text = this.items.map(column => column.toObject())

		if (!style) {
			return { text }
		}

		return {
			text,
			...this.getStyleObject(),
		}
	}
}
