import StateFactory from '@/store/helpers/StateFactory'

/**
 * The module's default state.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default function state() {
	return StateFactory.paginatedEntity({
		filters: {
			ids: [],
			search: '',
		},
	})
}
