import {
	LOCK_SCROLLING,
	UNLOCK_SCROLLING,
	UPDATE_ARIA_MESSAGE,
} from '@/store/mutation-types'

/**
 * Constant representing the CSS scroll lock class.
 *
 * @type {String}
 */
const SCROLL_LOCK_CLASS = 'scroll-lock'

/**
 * The module's state mutators.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default {
	/**
	 * Add the scroll lock css class to the body class list.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[LOCK_SCROLLING](state) {
		state.bodyClasses.push(SCROLL_LOCK_CLASS)
	},

	/**
	 * Remove the scroll lock css class from the body class list.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[UNLOCK_SCROLLING](state) {
		const index = state.bodyClasses.findIndex(className => {
			return className === SCROLL_LOCK_CLASS
		})

		if (index > -1) {
			state.bodyClasses.splice(index, 1)
		}
	},

	/**
	 * Update the ARIA screen-reader message.
	 *
	 * @param {Object} state
	 * @param {String} message
	 * @return {void}
	 */
	[UPDATE_ARIA_MESSAGE](state, message) {
		state.ariaMessage = message
	},
}
