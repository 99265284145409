import TableRow from './TableRow'
import TableBody from './TableBody'

/**
 * Table definition object.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Table {
	/**
	 * Create a new Table instance.
	 *
	 * @param {Array} widths
	 */
	constructor(widths = []) {
		this.widths = widths

		this.body = new TableBody()
	}

	/**
	 * Add a new row to the table.
	 *
	 * @return {TableRow}
	 */
	addRow() {
		const row = new TableRow()

		return this.body.addRow(row)
	}

	/**
	 * Set the table's widths.
	 *
	 * @param {Array} widths
	 * @return {Table}
	 */
	setWidths(widths) {
		this.widths = widths

		return this
	}

	/**
	 * Get the instance as a definition object.
	 *
	 * @return {Object}
	 */
	toObject() {
		return {
			table: {
				widths: this.widths,
				body: this.body.toArray(),
			},
		}
	}
}
