import { mapToArray } from './helpers/getters'

/**
 * Global store getters.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	/**
	 * Get all calls from the module.
	 *
	 * @param {Object} state
	 * @return {Array}
	 */
	calls(state) {
		return mapToArray(state.calls)
	},

	/**
	 * Get all calendars from the module.
	 *
	 * @param {Object} state
	 * @return {Array}
	 */
	calendars(state) {
		return mapToArray(state.calendars)
	},

	/**
	 * Get all call types from the module.
	 *
	 * @param {Object} state
	 * @return {Array}
	 */
	callTypes(state) {
		return mapToArray(state.callTypes)
	},

	/**
	 * Get all events from the module.
	 *
	 * @param {Object} state
	 * @return {Array}
	 */
	events(state) {
		return mapToArray(state.events)
	},

	/**
	 * Get all event types from the module.
	 *
	 * @param {Object} state
	 * @return {Array}
	 */
	eventTypes(state) {
		return mapToArray(state.eventTypes)
	},

	/**
	 * Get all pages from the module.
	 *
	 * @param {Object} state
	 * @return {Array}
	 */
	pages(state) {
		return mapToArray(state.pages)
	},

	/**
	 * Get the authenticated user's active partner model.
	 *
	 * @param {Object} state
	 * @return {Object?}
	 */
	partner(state) {
		const id = state.partners.active

		if (!state.partners.ids.includes(id)) {
			return null
		}

		return state.partners.items[id] || { id }
	},

	/**
	 * Get all partners from the module.
	 *
	 * @param {Object} state
	 * @return {Array}
	 */
	partners(state) {
		return mapToArray(state.partners)
	},

	/**
	 * Get all providers from the module.
	 *
	 * @param {Object} state
	 * @return {Array}
	 */
	providers(state) {
		return mapToArray(state.providers)
	},

	/**
	 * Get all time blocks from the module.
	 *
	 * @param {Object} state
	 * @return {Array}
	 */
	timeBlocks(state) {
		return mapToArray(state.timeBlocks)
	},
}
