import Vue from 'vue'
import './config/icons'
import 'moment-timezone'
import './config/calendar'
import App from './App.vue'
import './assets/css/tailwind.css'
import { version } from '../package.json'
import Clarus from 'App/Foundation/Kernel'

/**
 * Disable the Vue console production tip.
 */
Vue.config.productionTip = false

/**
 * Enable/disable Vue devtools in the environment file.
 */
Vue.config.devtools = Clarus.usesVueDevTools()

/**
 * Install the application as a Vue plugin.
 */
Vue.use(Clarus)

/**
 * Bind the clarus object containing the app version to the window.
 *
 * @type {Object}
 */
window.clarus = { version }

/**
 * Boot the application and mount the Vue instance.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
Clarus.boot().then(({ app, store, router }) => {
	new Vue({
		el: '#app',
		app,
		store,
		router,
		render: h => h(App),
	})
})
