// import PageReady from 'App/Middleware/PageReady'
import AuthCheck from 'App/Middleware/AuthCheck'
import AuthBooted from 'App/Middleware/AuthBooted'

/*
|--------------------------------------------------------------------------
| Global Middleware
|--------------------------------------------------------------------------
|
| Global middlware are executed before each page transition and act as the
| guard between a user and a route/page being loaded.
|
*/
const before = [AuthBooted, AuthCheck]

/*
|--------------------------------------------------------------------------
| Global After Middleware
|--------------------------------------------------------------------------
|
| After middlwares are executed after a router transition has been confirmed
| by all of the global and local component guards/middleware.
|
*/
const after = [
	// PageReady
]

/**
 * The application's middleware bindings.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default {
	before,
	after,
}
