import Logo from '../Logo'

/**
 * Image definition object.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Image {
	/**
	 * Create a new Image instance.
	 *
	 * @param {String} image
	 * @param {Number} width
	 * @param {Number} height
	 */
	constructor(image = Logo, width = 50, height = 50) {
		this.image = image

		this.height = height

		this.width = width
	}

	/**
	 * Set the image's height.
	 *
	 * @param {Number} height
	 * @return {Image}
	 */
	setHeight(height) {
		this.height = height

		return this
	}

	/**
	 * Set the image's width.
	 *
	 * @param {Number} width
	 * @return {Image}
	 */
	setWidth(width) {
		this.width = width

		return this
	}

	/**
	 * Get the instance as a definition object.
	 *
	 * @return {Object}
	 */
	toObject() {
		return {
			image: this.image,
			height: this.height,
			width: this.width,
		}
	}
}
