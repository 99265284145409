import Response from './Response'

/**
 * Http success response.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class SuccessResponse extends Response {
	/**
	 * Get the default response message type.
	 *
	 * @return {String}
	 */
	getDefaultType() {
		return 'success'
	}
}
