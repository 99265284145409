import Message from './Message'

/**
 * Alert warning message.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class WarningMessage extends Message {
	/**
	 * Create a new WarningMessage instance.
	 *
	 * @param {String} message
	 */
	constructor(message) {
		super('warning', 'Warning!', message)
	}
}
