import { api } from '@/store/helpers'
import actions from '@/store/helpers/actions'
import { ADD_EVENTS, REMOVE_EVENT, REMOVE_EVENTS } from '@/store/mutation-types'

/**
 * The module's dispatchable actions.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	...actions,

	/**
	 * Add/set the events for a specific calendar.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @param {Number} payload.calendarId
	 * @param {Array} payload.events
	 * @return {void}
	 */
	addEvents({ commit, state }, { calendarId, events }) {
		const id = parseInt(calendarId)

		if (state.ids.includes(id) && events.length > 0) {
			commit(ADD_EVENTS, { id, events })
		}
	},

	/**
	 * Remove the events for a specific calendar.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @param {Number} payload.calendarId
	 * @param {Array} payload.events
	 * @return {void}
	 */
	removeEvents({ commit }) {
		commit(REMOVE_EVENTS)
	},

	/**
	 * Fetch all partner's calendars.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.rootGetters
	 * @return {Response}
	 */
	async get({ dispatch, rootGetters }) {
		const partner = rootGetters.partner.id

		const response = await api()
			.partners()
			.calendars()
			.get(partner)

		const calendars = response.get('data', []).map(calendar => {
			return {
				...calendar,
				events: [],
			}
		})

		dispatch('add', calendars)

		return response
	},

	/**
	 * Remove an event for a specific calendar.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @param {Number} payload.calendarId
	 * @param {Number} payload.event
	 * @return {void}
	 */
	removeEvent({ commit, state }, { calendarId, event }) {
		const id = parseInt(calendarId)

		if (state.ids.includes(id)) {
			commit(REMOVE_EVENT, { id, event })
		}
	},
}
