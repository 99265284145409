import Vue from 'vue'
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

/**
 * Register the global v-calendar component.
 */
Vue.component('calendar', Calendar)
Vue.component('date-picker', DatePicker)

export default {
	components: {
		Calendar,
		DatePicker,
	},
}
