import Vue from 'vue'
import Application from 'App/Foundation/Application'

/**
 * Get the API Client instance from the container.
 *
 * @return {Client}
 */
export const api = () => Application.getInstance().api()

/**
 * Add the given models to the store's state.
 *
 * @param {Object} state
 * @param {Array} models
 * @return {void}
 */
export const add = (state, models) => {
	models.forEach(model => push(state, model))
}

/**
 * Prepend the given models to the store's state.
 *
 * @param {Object} state
 * @param {Array} models
 * @return {void}
 */
export const addToFront = (state, models) => {
	models.forEach(model => unshift(state, model))
}

/**
 * Push a new model into the store state.
 *
 * @param {Object} state
 * @param {Object} model
 * @return {void}
 */
export const push = (state, model) => {
	Vue.set(state.items, model.id, model)

	if (!state.ids.includes(model.id)) {
		state.ids.push(model.id)
	}
}

/**
 * Add/unshift a new model into the store state.
 *
 * @param {Object} state
 * @param {Object} model
 * @return {void}
 */
export const unshift = (state, model) => {
	Vue.set(state.items, model.id, model)

	if (!state.ids.includes(model.id)) {
		state.ids.unshift(model.id)
	}
}

/**
 * Remove a model from a store's state by id.
 *
 * @param {Object} state
 * @param {Number} id
 * @return {void}
 */
export const removeById = (state, id) => {
	delete state.items[id]

	state.ids.splice(
		state.ids.findIndex(item => item === id),
		1
	)
}

/**
 * Reset the given module state back to its original value.
 *
 * @param {Object} state
 * @param {Object} original
 * @return {void}
 */
export const reset = (state, original) => {
	Object.keys(original).forEach(key => {
		state[key] = original[key]
	})
}

/**
 * Replace/update a model's state in the store.
 *
 * @param {Object} state
 * @param {Object} model
 * @return {void}
 */
export const update = (state, model) => {
	state.items = {
		...state.items,
		[model.id]: model,
	}
}
