import { get } from 'lodash'
import messages from '@/config/messages'
import Factory from 'App/Alerts/Factory'

/**
 * Alert notifier manager.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Notifier {
	/**
	 * Create a new Notifier instance.
	 *
	 * @param {Dispatcher} dispatcher
	 */
	constructor(dispatcher) {
		/**
		 * The event bus/dispatcher instance.
		 *
		 * @type {Dispatcher}
		 */
		this.vm = dispatcher
	}

	/**
	 * Display a new "danger" alert message.
	 *
	 * @param {String} message
	 * @return {Notifier}
	 */
	danger(message) {
		return this.show('danger', this.getMessage(message))
	}

	/**
	 * Dispatch an alert event.
	 *
	 * @param {String} event
	 * @param {?Message} payload
	 * @return {Notifier}
	 */
	dispatch(event, payload = null) {
		this.vm.emit(`alert:${event}`, payload)

		return this
	}

	/**
	 * Display a new "error" alert message.
	 *
	 * @param {String} message
	 * @return {Notifier}
	 */
	error(message) {
		return this.show('error', this.getMessage(message))
	}

	/**
	 * Get the specific alert message.
	 *
	 * @param {String} key
	 * @return {String}
	 */
	getMessage(key) {
		return get(messages, key) || key
	}

	/**
	 * Handle an internal 500 error response.
	 *
	 * @return {Message}
	 */
	internalError() {
		return this.error(this._getDefaultErrorMessage())
	}

	/**
	 * Handle an internal 500 error response.
	 *
	 * @return {Message}
	 */
	invalidCredentials() {
		return this.error(this._getInvalidCredentialsMessage())
	}

	/**
	 * Display an alert message from a response.
	 *
	 * @param {Response} response
	 * @return {Message}
	 */
	response(response) {
		if (
			response?.getStatusCode() === 500 &&
			response?.config?.url === 'v4/auth/login'
		) {
			return this.invalidCredentials()
		}
		if (!this._isMessageableResponse(response)) {
			return this
		}

		if (response.getStatusCode() === 500) {
			return this.internalError()
		}

		return this.show(response.getType(), response.getMessage())
	}

	/**
	 * Show a new alert message.
	 *
	 * @param {String} type
	 * @param {String} message
	 * @return {Notifier}
	 */
	show(type, message) {
		return this.dispatch('show', Factory.make(type, message).show())
	}

	/**
	 * Display a new "success" alert message.
	 *
	 * @param {String} message
	 * @return {Notifier}
	 */
	success(message) {
		return this.show('success', this.getMessage(message))
	}

	/**
	 * Display a new "warning" alert message.
	 *
	 * @param {String} message
	 * @return {Notifier}
	 */
	warning(message) {
		return this.show('warning', this.getMessage(message))
	}

	/**
	 * Get the default error message.
	 *
	 * @return {String}
	 */
	_getDefaultErrorMessage() {
		return 'Something went wrong behind the scenes. Please try again or contact support.'
	}

	/**
	 * Get the invalid credential message.
	 *
	 * @return {String}
	 */
	_getInvalidCredentialsMessage() {
		return 'Invalid credentials please try again.'
	}

	/**
	 * Check if a response is alertable.
	 *
	 * @param {Response} response
	 * @return {Boolean}
	 */
	_isMessageableResponse(response) {
		try {
			if (this._isResponseInstance(response)) {
				return response.hasMessage() || response.getStatusCode() >= 500
			}
		} catch (e) {
			console.log('Response is not a messageable response', response)
			console.error(e)
		}

		return false
	}

	/**
	 * Determine if the given object is a Response instance.
	 *
	 * @param {Response} response
	 * @return {Boolean}
	 */
	_isResponseInstance(response) {
		return !!(
			response &&
			typeof response === 'object' &&
			typeof response.hasMessage === 'function' &&
			typeof response.getStatusCode === 'function'
		)
	}
}
