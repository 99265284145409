import Text from './Text'

/**
 * Table body definition object.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class TableBody {
	/**
	 * Make a new TableBody instance.
	 */
	constructor() {
		/**
		 * The table body's content table row collection.
		 *
		 * @type {Array}
		 */
		this.items = []
	}

	/**
	 * Add a new row to the table.
	 *
	 * @param {TableRow} row
	 * @return {TableRow}
	 */
	addRow(row) {
		this.items.push(row)

		return row
	}

	/**
	 * Add a new item text block to the table body.
	 *
	 * @param {String} item
	 * @param {String} style
	 * @return {Columns}
	 */
	text(item, style = null) {
		const text = new Text(item, style)

		this.items.push(text)

		return text
	}

	/**
	 * Get the instance as an array.
	 *
	 * @return {Array}
	 */
	toArray() {
		return this.items.map(row => row.toArray())
	}
}
