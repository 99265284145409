import stateFactory from './state'
import * as helpers from '@/store/helpers'
import {
	ADD_ITEMS,
	REMOVE_BY_ID,
	RESET,
	SET_ACTIVE,
	SET_ITEMS,
	SET_META,
	SET_PENDING_CALLS_COUNT,
	SET_PENDING_CALLS_COUNT_BY_ID,
} from '@/store/mutation-types'

/**
 * The module's state mutators.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default {
	/**
	 * Add the given model(s) to the store.
	 *
	 * @param {Object} state
	 * @param {Array} models
	 */
	[ADD_ITEMS](state, models) {
		helpers.add(state, models)
	},

	/**
	 * Remove a model from the module by id.
	 *
	 * @param {Object} state
	 * @param {Number} id
	 * @return {void}
	 */
	[REMOVE_BY_ID](state, id) {
		helpers.removeById(state, id)
	},

	/**
	 * Reset the given state back to its original value.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[RESET](state) {
		helpers.reset(state, stateFactory())
	},

	/**
	 * Set the application's current partner id for the authenticated user.
	 *
	 * @param {Object} state
	 * @param {Number} id
	 * @return {void}
	 */
	[SET_ACTIVE](state, id) {
		state.active = id
	},

	/**
	 * Replace the module's state with the given models.
	 *
	 * @param {Object} state
	 * @param {Array} models
	 * @return {void}
	 */
	[SET_ITEMS](state, models) {
		state.ids = []
		state.items = {}

		helpers.add(state, models)
	},

	/**
	 * Set the model meta data.
	 *
	 * @param {Object} state
	 * @param {Object} meta
	 * @return {void}
	 */
	[SET_META](state, meta) {
		state.meta = {
			...meta,
		}
	},

	/**
	 * Set the pending calls count for the current partner.
	 *
	 * @param {Object} state
	 * @param {Number} count
	 * @return {void}
	 */
	[SET_PENDING_CALLS_COUNT](state, count) {
		const partner = state.items[state.active]

		partner.pending_calls_count = count

		state.items = {
			...state.items,
			[state.active]: partner,
		}
	},

	[SET_PENDING_CALLS_COUNT_BY_ID](state, callCount) {
		const partner = state.items[state.active]
		state.items = {
			...state.items,
			[state.active]: {
				...partner,
				pending_calls_count:
					callCount?.total || partner.pending_calls_count,
			},
		}
	},
}
