import ApiServiceProvider from 'App/Api/ApiServiceProvider'
import PDFServiceProvider from 'App/PDF/PDFServiceProvider'
import HttpServiceProvider from 'App/Http/HttpServiceProvider'
import AuthServiceProvider from 'App/Auth/AuthServiceProvider'
import AppServiceProvider from 'App/Foundation/AppServiceProvider'
import AlertServiceProvider from 'App/Alerts/AlertServiceProvider'
import EventServiceProvider from 'App/Events/EventServiceProvider'

/*
|--------------------------------------------------------------------------
| Service Providers
|--------------------------------------------------------------------------
|
| The application's core service providers that are used to register each
| service within the IOC container. The providers should be ordered in terms
| of dependencies (ex. ApiProvider requires HttpProvider to be registered first).
|
*/
const providers = [
	AppServiceProvider,
	EventServiceProvider,
	AlertServiceProvider,
	HttpServiceProvider,
	ApiServiceProvider,
	AuthServiceProvider,
	PDFServiceProvider,
]

/*
|--------------------------------------------------------------------------
| Container Aliases
|--------------------------------------------------------------------------
|
| The application's container binding aliases  that are registered when the
| application is booting. These aliases are used to retrieve a binding quickly
| from the container instead of having to use the binding/service's full namespace.
|
*/
const aliases = {
	'App/Api/Client': ['api', 'http'],
	'App/Foundation/Container': 'ioc',
	'App/Foundation/Application': 'app',
	'App/Http/Request': 'request',
	'App/Events/Dispatcher': ['vm', 'event'],
}

/*
|--------------------------------------------------------------------------
| Vue Bindings
|--------------------------------------------------------------------------
|
| This object is used to create getters on the Vue prototype. Each alias and
| namespaces will have a getter defined to allow for ease of access in Vue
| components.
|
| The App/Foundation/Application instance has already been bound to "$app".
| Example: The instance is available (in Vue component) by using "this.$app."
|
| Example: { $bus: 'App/Events/Dispatcher' }
| Allows (in Vue component): this.$bus.emit(...)
|
*/
const vue = {
	$api: 'App/Api/Client',
	$alert: 'App/Alerts/Notifier',
}

/**
 * The application's service providers, container aliases and Vue bindings.
 *
 * @author
 */
export default {
	vue,
	aliases,
	providers,
}
