import moment from 'moment'
import { orderBy } from 'lodash'
import { any } from '@/store/helpers/getters'

/**
 * Get the channel name
 *
 * @param {Object} members
 * @param {String} streamProfileId
 * @return  {String}
 */
const getChannelName = (members, streamProfileId) => {
	const MAX_MEMBERS = 3
	let name = ''

	if (members.length === 0) {
		return name
	}

	for (let i = 0; i < members.length; i++) {
		const isNotActiveProfile = members[i].user_id !== streamProfileId

		if (isNotActiveProfile) {
			let { first_name, last_name } = members[i].user

			name += `${last_name}, ${first_name} - `
		}

		if (i >= MAX_MEMBERS) {
			break
		}
	}

	if (members.length - 1 > MAX_MEMBERS) {
		name += ` and ${members.length - MAX_MEMBERS} more...`
	}

	return name
}

/**
 * Get the array channel's members
 *
 * @param {Object} members
 * @return  {Array}
 */
const convertMembersToArray = members => {
	const keys = Object.keys(members)

	return keys.map(key => members[key])
}

/**
 * The module's reactive state getters.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	any,
	/**
	 * Get the current channels.
	 *
	 * @param {Object} state
	 * @param {Object} getters
	 * @return {Array}
	 */
	channels(state) {
		const ids =
			state.filters.search.length > 0 ? state.filters.ids : state.ids

		const channels = ids.map(id => state.items[id])

		return orderBy(
			channels,
			[
				channel =>
					moment(channel.last_message_at, 'YYYY-MM-DD[T]HH:mm:ss'),
			],
			['desc']
		)
	},

	find(state) {
		return id => {
			if (id && state.ids.indexOf(id) > -1) {
				return state.items[id]
			}

			return null
		}
	},

	/**
	 * Get the channel display name.
	 *
	 * @param   {Object} state
	 *
	 * @return  {String}
	 */
	channelName(state) {
		return (id, streamProfile) => {
			const members = convertMembersToArray(
				state.items[id]?.state?.members || {}
			)

			return getChannelName(members, streamProfile)
		}
	},

	/**
	 * Get all the messagingProfiles.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Array}
	 */
	all(state, getters) {
		if (!getters.any) {
			return []
		}

		return state.ids.map(id => state.items[id])
	},

	/**
	 * Get the meta object in the module.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	meta(state) {
		return state.meta
	},
}
