import Style from './Style'

/**
 * Text definition object.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Text {
	/**
	 * Create a new Text instance.
	 *
	 * @param {String} value
	 * @param {String} style
	 */
	constructor(value, style) {
		this.value = value

		this.style = style
	}

	/**
	 * Create a new custom Style instance for the text.
	 *
	 * @return {Style}
	 */
	addStyles() {
		this.style = new Style('text-style')

		return this.style
	}

	/**
	 * Get the custom styles definition or style name object.
	 *
	 * @return {Object}
	 */
	getStyleObject() {
		if (!this.style) {
			return
		}

		if (typeof this.style === 'string') {
			return { style: this.style }
		}

		return this.style.toObject()
	}

	/**
	 * Get the instance as a definition object.
	 *
	 * @return {Object}
	 */
	toObject() {
		const style = this.getStyleObject()

		if (!style) {
			return { text: this.value }
		}

		return {
			text: this.value,
			...this.getStyleObject(),
		}
	}

	/**
	 * Apply style to text.
	 *
	 * @param {String} name
	 * @return {void}
	 */
	useStyle(name) {
		this.style = name
	}
}
