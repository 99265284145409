/**
 * Authentication manager.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Auth {
	/**
	 * Create a new Auth instance.
	 *
	 * @param {Application}
	 */
	constructor(app) {
		/**
		 * The Application manager instance.
		 *
		 * @type {Application}
		 */
		this.app = app

		/**
		 * Has this instance been booted.
		 *
		 * @type {Boolean}
		 */
		this._booted = false
	}

	/**
	 * Determine if the user is currently authenticated.
	 *
	 * @return {Boolean}
	 */
	get authenticated() {
		return this.app.store.getters['auth/authenticated']
	}

	/**
	 * Boot the current instance and request the current user.
	 *
	 * @return {void}
	 */
	async boot() {
		if (this.booted()) {
			return
		}

		await this.check()

		this._booted = true

		this.emit('booted')
	}

	/**
	 * Determine if the Auth instance has been booted.
	 *
	 * @return {Boolean}
	 */
	booted() {
		return this._booted
	}

	/**
	 * Check the current user's auth status with the API.
	 *
	 * @return {Promise}
	 */
	async check() {
		try {
			const authenticated = await this.dispatch('check')

			if (authenticated) {
				this.emit('success', this.app)
			}

			return authenticated
		} catch (e) {
			return e
		}
	}

	/**
	 * Dispatch an action to the auth store.
	 *
	 * @param {String} action
	 * @param {*} payload
	 * @return {Promise}
	 */
	dispatch(action, payload) {
		return this.app.store.dispatch(`auth/${action}`, payload)
	}

	/**
	 * Emit a namespaced auth event.
	 *
	 * @param {String} event
	 * @param {*} payload
	 * @return {Auth}
	 */
	emit(event, payload) {
		this.app.emit(`auth:${event}`, payload)

		return this
	}

	/**
	 * Determine if the user is authenticated.
	 *
	 * @return {Boolean}
	 */
	isAuthenticated() {
		return this.authenticated
	}

	/**
	 * Determine if the current user is a guest.
	 *
	 * @return {Boolean}
	 */
	isGuest() {
		return !this.authenticated
	}

	/**
	 * Redirect the application/user to the login, dashboard or given route.
	 *
	 * @param {Object|String|null} route
	 * @return {Promise}
	 */
	redirectUser(route = null) {
		if (!route) {
			route = {
				name: this.isGuest() ? 'login' : 'app.dashboard',
			}
		}

		return this.app.router.push(route)
	}
}
