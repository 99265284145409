import { any, find } from '@/store/helpers/getters'

/**
 * The module's reactive state getters.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	any,
	find,

	/**
	 *  Get the linked partner by event id.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Object}
	 *
	 */
	getLinkedCalendar(state, getters) {
		return id => {
			if (!getters.isLinkedCalendarEvent(id)) {
				return null
			}

			const eventables = state.items[id].eventables

			return eventables[0]
		}
	},

	/**
	 *  Get the provider group by event id.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Object}
	 *
	 */
	getProviderGroup(state, getters) {
		return id => {
			if (!getters.isProviderGroupEvent(id)) {
				return null
			}

			const eventables = state.items[id].eventables

			return eventables[0]
		}
	},

	/**
	 *  Get the primary provider by event id.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Object}
	 *
	 */
	getPrimaryProvider(state, getters) {
		return id => {
			if (!getters.isProviderEvent(id)) {
				return null
			}

			const eventables = state.items[id].eventables

			return eventables[0]
		}
	},

	/**
	 * Get the secondary provider by event id.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Object}
	 *
	 */
	getSecondaryProvider(state, getters) {
		return id => {
			if (!getters.hasSecondaryProvider(id)) {
				return null
			}

			const eventables = state.items[id].eventables

			return eventables[1]
		}
	},

	/**
	 * Determine if the event has secondary provider.
	 *
	 * @param {Object} state
	 * @param {Function} getters
	 * @return {Boolean}
	 *
	 */
	hasSecondaryProvider(state, getters) {
		return id => {
			if (!getters.isProviderEvent(id)) {
				return false
			}

			return state.items[id].eventables.length > 1
		}
	},

	/**
	 * Determine if the given event has cover my call requests.
	 *
	 * @param {Object} state
	 * @return {Boolean}
	 *
	 */
	hasRequests(state) {
		return id => {
			return state.items[parseInt(id)]?.requests.length >= 1
		}
	},

	/**
	 * Determine if the selected event is a linked calendar event.
	 *
	 * @param {Object} state
	 * @return {Boolean}
	 */
	isLinkedCalendarEvent(state) {
		return id => {
			if (!state.ids.includes(id)) {
				return false
			}

			return state.items[id].type.name === 'linked-calendar'
		}
	},

	/**
	 * Determine if the given event is a primary calendar event.
	 *
	 * @param {Object} state
	 * @return {Boolean}
	 */
	isPrimaryCalendarEvent(state) {
		return id => {
			if (!state.ids.includes(parseInt(id))) {
				return false
			}

			const event = state.items[id]

			return event.calendar_id === event.primary_calendar_id
		}
	},

	/**
	 * Determine if the selected event is a provider event.
	 *
	 * @param {Object} state
	 * @return {Boolean}
	 */
	isProviderEvent(state) {
		return id => {
			if (!state.ids.includes(id)) {
				return false
			}

			return state.items[id].type.name === 'provider'
		}
	},

	/**
	 * Determine if the selected event is provider group event.
	 *
	 * @param {Object} state
	 * @return {Boolean}
	 */
	isProviderGroupEvent(state) {
		return id => {
			if (!state.ids.includes(id)) {
				return false
			}

			return state.items[id].type.name === 'provider-group'
		}
	},

	/**
	 * Determine if the event has been shared.
	 *
	 * @param {Object} state
	 * @return {Boolean}
	 */
	isSharedEvent(state) {
		return id => {
			if (!state.ids.includes(parseInt(id))) {
				return false
			}

			return state.items[id].calendars.length > 1
		}
	},
}
