import Application from 'App/Foundation/Application'

/**
 * The messaging scope.
 *
 * @type {String}
 */
const SCOPE = 'messaging'

/**
 * Stream Chat wrapper class.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class StreamChat {
	/**
	 * Attempt to disconnect the user from the Stream Chat API.
	 *
	 * @return  {Promise}
	 */
	static async disconnectUser() {
		const app = Application.getInstance()

		return await app.container.use('Stream')
	}

	/**
	 * Query the Stream Chat API for the user's current team.
	 *
	 * @param   {Object}  query
	 * @return  {Promise}
	 */
	static queryUsers(query) {
		const app = Application.getInstance()

		const client = app.container.use('Stream')

		return client.queryUsers(query)
	}

	/**
	 * Get the connected user.
	 *
	 * @return  {Promise}
	 */
	static getUser() {
		const app = Application.getInstance()

		return app.container.use('Stream').user
	}

	/**
	 * Query the Stream Chat API for the channels for the current team.
	 *
	 * @param   {Object}  filter
	 * @param   {Object}  sort
	 * @return  {Promise}
	 */
	static async queryChannels(
		filter,
		sort = [{ last_message_at: -1 }],
		offset = 1
	) {
		const app = Application.getInstance()

		const client = app.container.use('Stream')

		return await client.queryChannels(filter, sort, {
			watch: true,
			state: true,
			limit: 30,
			offset,
		})
	}

	/**
	 * Create a stream channel.
	 *
	 * @param   {String}  name
	 * @param   {Array}  members
	 * @param   {Stream}  team
	 * @return  {Promise}
	 */
	static createChannel(name, members, team) {
		const app = Application.getInstance()

		const client = app.container.use('Stream')

		const channel = client.channel(SCOPE, {
			name,
			members,
			team,
		})

		return channel.create()
	}

	/**
	 * Send a message to the given stream channel.
	 *
	 * @param   {Object}  channel
	 * @param   {Object}  message
	 * @return  {Promise}
	 */
	static async sendMessage(channel, text) {
		return await channel.sendMessage({ text })
	}
}
