import stateFactory from './state'
import * as helpers from '@/store/helpers'
import { ADD_ITEMS, RESET } from '@/store/mutation-types'

/**
 * The module's state mutators.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	/**
	 * Add the given model(s) to the store.
	 *
	 * @param {Object} state
	 * @param {Array} models
	 */
	[ADD_ITEMS](state, models) {
		helpers.add(state, models)
	},

	/**
	 * Reset the given state back to its original value.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[RESET](state) {
		helpers.reset(state, stateFactory())
	},
}
