import pdfMake from 'pdfmake'
import Builder from './Builder'
import CallPDF from './CallPDF'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ServiceProvider from 'App/Support/ServiceProvider'

/**
 * PDF service provider.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class PDFServiceProvider extends ServiceProvider {
	/**
	 * Register bindings into the container.
	 *
	 * @return {void}
	 */
	register() {
		this.app.singleton('pdfMake', () => {
			pdfMake.vfs = pdfFonts.pdfMake.vfs

			return pdfMake
		})

		this.app.bind('App/PDF/Builder', ioc => {
			return new Builder(ioc.use('pdfMake'))
		})

		this.app.bind('App/PDF/CallPDF', ioc => {
			const builder = ioc.use('App/PDF/Builder')

			return new CallPDF(builder)
		})
	}
}
