<template>
	<transition name="snackbar-slide" appear :duration="1000">
		<div v-show="visible" class="animated snackbar" :class="type">
			<div class="snackbar-icon">
				<font-awesome-icon :icon="['fal', icon]" />
			</div>
			<div class="snackbar-text">{{ title }} {{ body }}</div>
			<div class="snackbar-action">
				<button
					type="button"
					class="snackbar-btn"
					@click.prevent="onCloseBtnClick"
				>
					CLOSE
				</button>
			</div>
		</div>
	</transition>
</template>
<script>
export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the alert's icon based on the alert type.
		 *
		 * @return {String}
		 */
		icon() {
			switch (this.type) {
				case 'error':
					return 'times'
				case 'warning':
					return 'exclamation'
				case 'success':
				default:
					return 'check'
			}
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * On close button click event handler.
		 *
		 * @return {void}
		 */
		onCloseBtnClick() {
			this.$app.use('App/Alerts/Notifier').dispatch('hide', this.id)
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'AlertMessage',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The alert message's body.
		 *
		 * @type {Object}
		 */
		body: {
			type: String,
			required: true,
		},

		/**
		 * The alert message unique id.
		 *
		 * @type {Object}
		 */
		id: {
			type: String,
			required: true,
		},

		/**
		 * The alert message's autohide timer id.
		 *
		 * @type {Object}
		 */
		timer: {
			type: Number,
			default: 0,
		},

		/**
		 * The message's title / prefix.
		 *
		 * @type {Object}
		 */
		title: {
			type: String,
			required: true,
		},

		/**
		 * The message type.
		 *
		 * @type {Object}
		 */
		type: {
			type: String,
			required: true,
		},

		/**
		 * The message's visibility/show flag.
		 *
		 * @type {Object}
		 */
		visible: {
			type: Boolean,
			required: true,
		},
	},
}
</script>
