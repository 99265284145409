import { api } from '@/store/helpers'
import actions from '@/store/helpers/actions'

/**
 * The module's dispatchable actions.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	...actions,

	/**
	 * Fetch all event types.
	 *
	 * @param {Function} options.dispatch
	 * @return {Response}
	 */
	async get({ dispatch }) {
		const response = await api()
			.eventTypes()
			.get()

		dispatch('add', response.get('data'))

		return response
	},
}
