import StateFactory from '@/store/helpers/StateFactory'

/**
 * The module's default state.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default function state() {
	let currentVersion = null

	try {
		currentVersion = StateFactory.getFromStorage('version', null)
	} catch (e) {
		localStorage.removeItem('version')
	}

	return {
		user: StateFactory.getFromStorage('user'),
		session: StateFactory.getFromStorage('session'),
		version: currentVersion,
	}
}
