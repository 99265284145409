import {
	LOGOUT,
	SET_SESSION,
	SET_USER,
	SET_VERSION,
} from '@/store/mutation-types'

/**
 * The module's state mutators.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default {
	/**
	 * Remove the user/session data from the store.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[LOGOUT](state) {
		state.user = {}
		state.session = {}
	},

	/**
	 * Set the current session's state.
	 *
	 * @param {Object} state
	 * @param {Object} session
	 * @return {void}
	 */
	[SET_SESSION](state, session) {
		state.session = {
			...state.session,
			...session,
		}
	},

	/**
	 * Set the current authenticated user's data.
	 *
	 * @param {Object} state
	 * @param {Object} user
	 * @return {void}
	 */
	[SET_USER](state, user) {
		state.user = {
			...state.user,
			...user,
		}
	},

	/**
	 * Set the current project version.
	 *
	 * @param {Object} state
	 * @param {String} user
	 * @return {void}
	 */
	[SET_VERSION](state, version) {
		state.version = version
	},
}
