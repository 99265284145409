import Task from './Task'

/**
 * Boot the registered service providers.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class RegisterServiceProviders extends Task {
	/**
	 * Run the asynchronous task and resolve when complete.
	 *
	 * @param {Function} next
	 * @return {void}
	 */
	async run(next) {
		await this.registrar.boot()

		next()
	}
}
