/**
 * The module's default state.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default function state() {
	return {
		/**
		 * The application's data fetching state.
		 */
		fetching: {
			message: '',
			status: false,
		},

		/**
		 * The application's locked state.
		 */
		locked: {
			message: '',
			status: false,
		},

		/**
		 * The application's page loading state.
		 */
		page: {
			message: 'Building application',
			status: true,
		},
	}
}
